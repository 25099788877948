.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10vh;
    width: 100%;
    padding-top: 15vh;
    background-image: url(../../utils/images/headbg.jpg);
    background-repeat: no-repeat;
    background-size: 100vw auto;
    background-position: top center;
}

.textTitulo{
    color: white;
    font-size: 1.6vw;
    font-weight: bold;
    margin: 1.2vh 0vw;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding: 5vh 14vw;
}

.text{
    font-size: 0.8vw;
    line-height: 3.2vh;
}



@media only screen and (min-width: 821px) and (max-width: 1150px){
    
    .textTitulo{
        color: white;
        font-size: 4vh;
        font-weight: bold;
        margin: 1.2vh 0vw;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    
    .textTitulo{
        color: white;
        font-size: 2.2vh;
        font-weight: bold;
        margin: 1.2vh 0vw;
    }
}

@media (max-width:650px) {

    .container{
        padding-top: 12vh;
    }
    
    .textTitulo{
        color: white;
        font-size: 2.2vh;
        font-weight: bold;
        margin: 1.2vh 0vw;
        padding: 0vh 5vw;
        text-align: center;
    }
}