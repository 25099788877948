.clientes{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #f3f9ff;
    padding-bottom: 5vh;
}

.nuestroC{
    font-size: 1.6vw;
    color: rgb(113, 113, 113);
    margin: 1.7vh;
    margin-top: 5;
}

.separador{
    border: solid 0.1vh;
    width: 4vw;
}

.carrusel{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70vw;
    margin: 5vh 0vw;
    overflow: hidden;
}

.divPaginas{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70vw;
    margin: 5vh 0vw;
    margin-left: 0vw;
    animation: moverCarrusel 30s infinite;
}

.pagina{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.imagen{
    width: 10vw;
    margin: 0vh 2vw;
}

@keyframes moverCarrusel {
    0%{
        margin-left: 0vw;
    }
    9.96%{
        margin-left: -100%;
    }
    16.6%{
        margin-left: -100%;
    }
    26.56%{
        margin-left: -200%;
    }
    33.2%{
        margin-left: -200%;
    }
    43.16%{
        margin-left: -300%;
    }
    49.8%{
        margin-left: -300%;
    }
    59.76%{
        margin-left: -400%;
    }
    66.4%{
        margin-left: -400%;
    }
    76.36%{
        margin-left: -440%;
    }
    83%{
        margin-left: -440%;
    }
    92.96%{
        margin-left: 0vw;
    }
    99.6%{
        margin-left: 0vw;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){

    .nuestroC{
        font-size: 5vh;
    }
    
    .separador{
        width: 6vw;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .carrusel{
        width: 72vw;
        margin: 2vh 0vw;
        overflow: hidden;
    }

    .nuestroC{
        font-size: 2.5vh;
    }
    
    .separador{
        border: solid 0.1vh;
        width: 8vw;
    }
    
    .divPaginas{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 72vw;
        margin: 5vh 0vw;
        margin-left: 0%;
        animation: moverCarrusel 30s infinite;
    }
    
    .pagina{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .imagen{
        width: 12vw;
        margin: 0vh 3vw;
    }
    
    @keyframes moverCarrusel {
        0%{
            margin-left: 0%;
        }
        8.568%{
            margin-left: -100%;
        }
        14.28%{
            margin-left: -100%;
        }
        22.848%{
            margin-left: -200%;
        }
        28.56%{
            margin-left: -200%;
        }
        37.128%{
            margin-left: -300%;
        }
        42.84%{
            margin-left: -300%;
        }
        51.408%{
            margin-left: -400%;
        }
        57.12%{
            margin-left: -400%;
        }
        65.688%{
            margin-left: -500%;
        }
        71.4%{
            margin-left: -500%;
        }
        79.968%{
            margin-left: -575%;
        }
        85.68%{
            margin-left: -575%;
        }
        94.248%{
            margin-left: 0%;
        }
        99.96%{
            margin-left: 0%;
        }
    }
}

@media (max-width:650px) {
    .clientes{
        padding-bottom: 3vh;
    }
    
    .nuestroC{
        font-size: 2.8vh;
    }
    
    .separador{
        border: solid 0.1vh;
        width: 15vw;
    }
    
    .carrusel{
        width: 75vw;
        margin: 2vh 0vw;
        overflow: hidden;
    }
    
    .divPaginas{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75vw;
        margin: 5vh 0vw;
        margin-left: 0%;
        animation: moverCarrusel 30s infinite;
    }
    
    .pagina{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .imagen{
        width: 22vw;
        margin: 0vh 1.5vw;
    }
    
    @keyframes moverCarrusel {
        0%{
            margin-left: 0%;
        }
        6.66%{
            margin-left: -100%;
        }
        11.11%{
            margin-left: -100%;
        }
        17.77%{
            margin-left: -200%;
        }
        22.22%{
            margin-left: -200%;
        }
        28.88%{
            margin-left: -300%;
        }
        33.33%{
            margin-left: -300%;
        }
        39.99%{
            margin-left: -400%;
        }
        44.44%{
            margin-left: -400%;
        }
        51.1%{
            margin-left: -500%;
        }
        55.55%{
            margin-left: -500%;
        }
        62.21%{
            margin-left: -600%;
        }
        66.66%{
            margin-left: -600%;
        }
        73.32%{
            margin-left: -700%;
        }
        77.77%{
            margin-left: -700%;
        }
        84.43%{
            margin-left: -800%;
        }
        88.88%{
            margin-left: -800%;
        }
        95.54%{
            margin-left: 0%;
        }
        99.99%{
            margin-left: 0%;
        }
    }
}