.productos {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background: linear-gradient(170deg, white 50%, #f3f9ff 50%);
}

.divText1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
}

.text1 {
    font-size: 1.3vw;
    text-align: center;
    font-weight: bold;
}

.text2 {
    font-size: 1.3vw;
    text-align: center;
    color: gray;
}

.divCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 47vh;
    margin-top: 5vh;
    gap: 20px;
}

.cards1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 72vw;
}

.cards2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 72vw;
}

.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 22vh;
    width: 35vw;
    border: solid #e0e0e8 0.1vh;
    border-radius: 1vh;
    background: white;
}

.imagen {
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: -4vw;
    height: 11vw;
}

.divTextCard {
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 22vw;
    margin-left: 10vw;
}

.divTextCard:hover {
    cursor: pointer;
}

.divTextArrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.textCard1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vh;
    font-size: 1vw;
    font-weight: bold;
    height: 3vh;
    color: black;
    width: 20vw;
}

.arrowFalse {
    display: flex;
    margin-left: 0.2vw;
    font-size: 1vw;
    color: black;
}

.arrowTrue {
    display: flex;
    margin-left: 0.2vw;
    font-size: 1vw;
    animation: girarArrow 1s forwards;
    color: black;
}

.textCard2 {
    margin: 1vh;
    font-size: 1vw;
    color: grey;
}

.otrosProductos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70vw;
    margin-top: 3vh;
    padding: 8vh 0vw;
}

.divTextProd {
    display: flex;
    flex-direction: column;
}

.textProd1 {
    margin: 0.5vh;
    font-size: 1.4vw;
    color: rgb(66, 66, 66);
}

.textProd2 {
    margin: 0.5vh;
    font-size: 1.4vw;
    font-weight: bold;
}

.divButtonProd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 27vw;
}

.verProductos {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    width: 13vw;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: white;
    background: #5987f7;
    font-weight: bold;
    white-space: nowrap;
}

.verProductos:hover {
    animation: backgroundProductos 0.3s forwards;
    cursor: pointer;
}

.consultas {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    width: 13vw;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: black;
    background: white;
    font-weight: bold;
    box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    white-space: nowrap;
}

.consultas:hover {
    cursor: pointer;
    animation: backgroundConsultas 0.3s forwards;
}

.link {
    text-decoration: none;
}

@keyframes girarArrow {
    100% {
        transform: rotateX(180deg);
        font-size: 1vw;
    }
}

@keyframes backgroundProductos {
    100% {
        background: grey;
    }
}

@keyframes backgroundConsultas {
    100% {
        background: #f7f7f7;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px) {
    .productos {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 5vh;
        padding-bottom: 5vh;
        background: linear-gradient(170deg, white 50%, #f3f9ff 50%);
    }

    .divText1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50vw;
    }

    .text1 {
        font-size: 3.2vh;
        text-align: center;
        font-weight: bold;
    }

    .text2 {
        font-size: 3.2vh;
        text-align: center;
        color: gray;
        margin: 0;
    }

    .divCards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 65vh;
        margin-top: 8vh;
    }

    .cards1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
    }

    .cards2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
    }

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 30vh;
        width: 39vw;
        border: solid #e0e0e8 0.1vh;
        border-radius: 1vh;
        background: white;
    }

    .imagen {
        display: flex;
        align-items: center;
        position: absolute;
        margin-left: -6vw;
        height: 13vw;
    }

    .divTextCard {
        display: flex;
        flex-direction: column;
        height: 23vh;
        width: 26vw;
        margin-left: 10vw;
        padding-top: 2vh;
    }

    .divTextArrow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .textCard1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1vh;
        font-size: 2.5vh;
        font-weight: bold;
        height: 3vh;
        color: black;
        width: 20vw;
    }

    .arrowFalse {
        display: flex;
        margin-left: 0.2vw;
        font-size: 3vh;
        color: black;
    }

    .arrowTrue {
        display: flex;
        margin-left: 0.2vw;
        font-size: 3vh;
        animation: girarArrow 1s forwards;
        color: black;
    }

    .textCard2 {
        margin: 1vh;
        font-size: 2.5vh;
        color: grey;
    }

    .otrosProductos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 75vw;
        margin-top: 3vh;
        padding: 8vh 0vw;
    }

    .divTextProd {
        display: flex;
        flex-direction: column;
    }

    .textProd1 {
        margin: 0.5vh;
        font-size: 3.5vh;
        color: rgb(66, 66, 66);
    }

    .textProd2 {
        margin: 0.5vh;
        font-size: 3.5vh;
        font-weight: bold;
    }

    .divButtonProd {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 35vw;
    }

    .verProductos {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        width: 17vw;
        border-radius: 1.2vh;
        font-size: 2.5vh;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }

    .consultas {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        width: 17vw;
        border-radius: 1.2vh;
        font-size: 2.5vh;
        color: black;
        background: white;
        font-weight: bold;
        box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px) {
    .productos {
        padding-top: 5vh;
        padding-bottom: 0vh;
    }

    .divText1 {
        width: 80vw;
    }

    .text1 {
        font-size: 2.2vh;
    }

    .text2 {
        font-size: 2.2vh;
    }

    .divCards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin-top: 2vh;
        gap: 10px;
    }

    .cards1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        gap: 10px;
    }

    .cards2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        gap: 10px;
    }

    .card {
        height: 20vh;
        width: 80vw;
        border: solid silver 0.1vh;
        border-radius: 2vh;
        background: white;
        margin: 1vh 0vw;
        padding: 1vh 0vw 1vh 0vw;
    }

    .imagen {
        margin-left: -12vw;
        height: 25vw;
    }

    .divTextCard {
        flex-direction: column;
        height: 20vh;
        width: 58vw;
        margin-left: 18vw;
    }

    .textCard1 {
        margin: 1vh 0vw 0vh 0vw;
        font-size: 1.9vh;
        height: 3vh;
        width: 58vw;
    }

    .arrowFalse {
        display: none;
        margin-left: 0.2vw;
        font-size: 20px;
    }

    .arrowTrue {
        display: none;
        margin-left: 0.2vw;
        width: 20px;
        font-size: 20px;
        animation: girarArrow 1s forwards;
        color: black;
    }

    .textCard2 {
        margin: 2vh 2vw 0vh 0vw;
        font-size: 1.9vh;
        color: grey;
        width: 55vw;
    }

    .otrosProductos {
        flex-direction: column;
        width: 80vw;
        margin-top: 0vh;
        padding: 5vh 0vw 3vh 0vw;
    }

    .divTextProd {
        display: flex;
        flex-direction: column;
    }

    .textProd1 {
        margin: 0.5vh;
        font-size: 4.5vw;
        color: rgb(113, 113, 113);
        text-align: center;
    }

    .textProd2 {
        margin: 0.5vh;
        font-size: 2.8vh;
        font-weight: 100;
        text-align: center;
    }

    .divButtonProd {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3vh 0vw;
        height: 12vh;
        width: 100%;
    }

    .verProductos {
        height: 5vh;
        width: 40vw;
        border-radius: 1vh;
        font-size: 1.5vh;
        text-align: center;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }

    .consultas {
        height: 5vh;
        width: 40vw;
        border-radius: 1vh;
        font-size: 1.5vh;
        text-align: center;
        color: black;
        background: white;
        font-weight: bold;
        box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    }
}

@media (max-width:650px) {
    .productos {
        padding-top: 5vh;
        padding-bottom: 0vh;
    }

    .divText1 {
        width: 80vw;
    }

    .text1 {
        font-size: 2.2vh;
    }

    .text2 {
        font-size: 2.2vh;
    }

    .divCards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin-top: 2vh;
        gap: 10px;
    }

    .cards1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        gap: 10px;
    }

    .cards2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        gap: 10px;
    }

    .card {
        height: 24vh;
        width: 80vw;
        border: solid silver 0.1vh;
        border-radius: 2vh;
        background: white;
        margin: 1vh 0vw;
    }

    .imagen {
        margin-left: -15vw;
        height: 30vw;
    }

    .divTextCard {
        flex-direction: column;
        height: 20vh;
        width: 55vw;
        margin-left: 22vw;
    }

    .textCard1 {
        margin: 1vh 0vw 0vh 0vw;
        font-size: 1.9vh;
        height: 3vh;
        width: 55vw;
    }

    .arrowFalse {
        display: none;
        margin-left: 0.2vw;
        font-size: 20px;
    }

    .arrowTrue {
        display: none;
        margin-left: 0.2vw;
        width: 20px;
        font-size: 20px;
        animation: girarArrow 1s forwards;
        color: black;
    }

    .textCard2 {
        margin: 2vh 2vw 0vh 0vw;
        font-size: 1.9vh;
        color: grey;
        width: 55vw;
    }

    .otrosProductos {
        flex-direction: column;
        width: 80vw;
        margin-top: 0vh;
        padding: 5vh 0vw 3vh 0vw;
    }

    .divTextProd {
        display: flex;
        flex-direction: column;
    }

    .textProd1 {
        margin: 0.5vh;
        font-size: 6vw;
        color: rgb(113, 113, 113);
        text-align: center;
    }

    .textProd2 {
        margin: 0.5vh;
        font-size: 2.8vh;
        font-weight: 100;
        text-align: center;
    }

    .divButtonProd {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3vh 0vw;
        height: 12vh;
        width: 100%;
    }

    .verProductos {
        height: 5vh;
        width: 50vw;
        border-radius: 1vh;
        font-size: 1.8vh;
        text-align: center;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }

    .consultas {
        height: 5vh;
        width: 50vw;
        border-radius: 1vh;
        font-size: 1.8vh;
        text-align: center;
        color: black;
        background: white;
        font-weight: bold;
        box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    }

    .consultas:hover {
        cursor: pointer;
        animation: backgroundConsultas 0.3s forwards;
    }

    .link {
        text-decoration: none;
    }
}