.footer {
    display: flex;
    flex-direction: row;
    width: 75%;
    padding: 6vh 9% 9vh 16%;
    background: #28375e;
}

.area1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}

.area2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
}

.divColumnA {
    display: flex;
    flex-direction: column;
    width: 20vw;
}

.divColumnB {
    display: flex;
    flex-direction: column;
    width: 17vw;
    margin-left: 4vw;
}

.divColumnC {
    display: flex;
    flex-direction: column;
    width: 13vw;
    padding-left: 2vw;
}

.divColumnD {
    display: flex;
    flex-direction: column;
    width: 17vw;
}

.title {
    font-size: 1.1vw;
    color: white;
    font-weight: bold;
    margin: 1vh 0vw;
}

.text {
    color: #9fb2e4;
    font-size: 0.9vw;
    margin: 1vh 0vw;
}

.text:hover {
    cursor: pointer;
    color: white;
}

.llamar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3vh;
    padding-left: 0.6vw;
    border-radius: 0.6vh;
}

.llamar:hover {
    background: #5e75ae;
    cursor: pointer;
}

.icon {
    display: flex;
    position: absolute;
    font-size: 1.4vw;
    color: white;
    margin-right: 0.6vw;
}

.numero {
    font-size: 1.1vw;
    color: white;
    margin: 1vh 0vw 1vh 2vw;
    text-decoration: none;
    white-space: nowrap;
}

.direccion {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3vh;
    padding-left: 0.6vw;
    margin-top: 0.8vh;
    border-radius: 0.6vh;
}

.direccion:hover {
    background: #5e75ae;
    cursor: pointer;
}

.divDireccion {
    display: flex;
    flex-direction: column;

}

.textDireccion {
    color: white;
    font-size: 1vw;
    margin: 0 0 0 2vw;
}

.email {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3vh;
    padding-left: 0.6vw;
    border-radius: 0.6vh;
    margin-top: 0.8vh;
}

.email:hover {
    background: #5e75ae;
    cursor: pointer;
}

.correo {
    font-size: 1.1vw;
    color: white;
    margin: 1vh 0vw 1vh 2vw;
}

.link {
    text-decoration: none;
}

@media only screen and (min-width: 821px) and (max-width: 1150px) {
    .footer {
        display: flex;
        flex-direction: row;
        width: 75%;
        padding: 6vh 9% 9vh 16%;
        background: #28375e;
    }

    .area1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 50%;
    }

    .area2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 50%;
    }

    .divColumnA {
        display: flex;
        flex-direction: column;
        width: 22vw;
    }

    .divColumnB {
        display: flex;
        flex-direction: column;
        width: 17vw;
        margin-left: 4vw;
    }

    .divColumnC {
        display: flex;
        flex-direction: column;
        width: 13vw;
        padding-left: 2vw;
    }

    .divColumnD {
        display: flex;
        flex-direction: column;
        width: 17vw;
    }

    .title {
        font-size: 3vh;
        color: white;
        font-weight: bold;
        margin: 1vh 0vw;
    }

    .text {
        color: #9fb2e4;
        font-size: 2.5vh;
        margin: 1vh 0vw;
    }

    .text:hover {
        cursor: pointer;
        color: white;
    }

    .llamar {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.3vh;
        padding-left: 0.6vw;
        border-radius: 0.6vh;
    }

    .llamar:hover {
        background: #5e75ae;
        cursor: pointer;
    }

    .icon {
        display: flex;
        position: absolute;
        font-size: 3.5vh;
        color: white;
        margin-right: 0.6vw;
    }

    .numero {
        font-size: 3vh;
        color: white;
        margin: 1vh 0vw 1vh 2vw;
        text-decoration: none;
        white-space: nowrap;
    }

    .direccion {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.3vh;
        padding-left: 0.6vw;
        margin-top: 0.8vh;
        border-radius: 0.6vh;
    }

    .direccion:hover {
        background: #5e75ae;
        cursor: pointer;
    }

    .divDireccion {
        display: flex;
        flex-direction: column;

    }

    .textDireccion {
        color: white;
        font-size: 2.5vh;
        margin: 0 0 0 2vw;
    }

    .email {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.3vh;
        padding-left: 0.6vw;
        border-radius: 0.6vh;
        margin-top: 0.8vh;
    }

    .email:hover {
        background: #5e75ae;
        cursor: pointer;
    }

    .correo {
        font-size: 3vh;
        color: white;
        margin: 1vh 0vw 1vh 2vw;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px) {
    .footer {
        flex-direction: column;
        align-items: center;
        width: 90vw;
        padding: 5vh 5vw 5vh 5vw;
    }

    .area1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
    }

    .area2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
    }

    .divColumnA {
        display: flex;
        flex-direction: column;
        width: 50vw;
    }
    
    .divColumnB {
        display: flex;
        flex-direction: column;
        width: 50vw;
        margin-left: 0vw;
    }
    
    .divColumnC {
        display: flex;
        flex-direction: column;
        width: 50vw;
        padding-left: 0vw;
    }
    
    .divColumnD {
        display: flex;
        flex-direction: column;
        width: 50vw;
    }

    .title {
        font-size: 3vh;
        color: white;
        font-weight: bold;
        margin: 1vh 0vw;
    }

    .text {
        color: #9fb2e4;
        font-size: 2.5vh;
        margin: 1vh 0vw;
    }

    .text:hover {
        cursor: pointer;
        color: white;
    }

    .llamar {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0.3vh;
        padding-left: 0.6vw;
        border-radius: 0.6vh;
    }

    .icon {
        display: flex;
        position: relative;
        font-size: 2.7vh;
        color: white;
        margin-right: 0.6vw;
        margin: 0vh 0vw 1vh 0vw;
    }

    .numero {
        font-size: 2vh;
        color: white;
        margin: 0vh 0vw 1vh 0vw;
        text-decoration: none;
        white-space: nowrap;
    }

    .direccion {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0.3vh;
        padding-left: 0.6vw;
        margin-top: 0.8vh;
        border-radius: 0.6vh;
    }

    .direccion:hover {
        background: #5e75ae;
        cursor: pointer;
    }

    .divDireccion {
        display: flex;
        flex-direction: column;

    }

    .textDireccion {
        color: white;
        font-size: 2vh;
        margin: 0;
    }

    .email {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0.3vh;
        padding-left: 0.6vw;
        border-radius: 0.6vh;
        margin-top: 0.8vh;
    }

    .correo {
        font-size: 2vh;
        color: white;
        margin: 0vh 0vw 1vh 0vw;
    }
}

@media (max-width:650px) {
    .footer {
        flex-direction: column;
        align-items: center;
        width: 90vw;
        padding: 5vh 5vw 5vh 5vw;
    }

    .area1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .area2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .divColumnA {
        display: flex;
        flex-direction: column;
        width: 50vw;
    }
    
    .divColumnB {
        display: flex;
        flex-direction: column;
        width: 50vw;
        margin-left: 3vw;
    }
    
    .divColumnC {
        display: flex;
        flex-direction: column;
        width: 50vw;
        padding-left: 0vw;
    }
    
    .divColumnD {
        display: flex;
        flex-direction: column;
        width: 50vw;
        margin-left: 4vw;
    }

    .title {
        font-size: 2.5vh;
        color: white;
        font-weight: bold;
        margin: 1vh 0vw;
    }

    .text {
        color: #9fb2e4;
        font-size: 2vh;
        margin: 1vh 0vw;
    }

    .text:hover {
        cursor: pointer;
        color: white;
    }

    .llamar {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0.3vh;
        padding-left: 0.6vw;
        border-radius: 0.6vh;
    }

    .icon {
        display: flex;
        position: relative;
        font-size: 2.7vh;
        color: white;
        margin-right: 0.6vw;
        margin: 0vh 0vw 1vh 0vw;
    }

    .numero {
        font-size: 2vh;
        color: white;
        margin: 0vh 0vw 1vh 0vw;
        text-decoration: none;
        white-space: nowrap;
    }

    .direccion {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0.3vh;
        padding-left: 0.6vw;
        margin-top: 0.8vh;
        border-radius: 0.6vh;
    }

    .direccion:hover {
        background: #5e75ae;
        cursor: pointer;
    }

    .divDireccion {
        display: flex;
        flex-direction: column;

    }

    .textDireccion {
        color: white;
        font-size: 2vh;
        margin: 0;
    }

    .email {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0.3vh;
        padding-left: 0.6vw;
        border-radius: 0.6vh;
        margin-top: 0.8vh;
    }

    .correo {
        font-size: 2vh;
        color: white;
        margin: 0vh 0vw 1vh 0vw;
    }
}