.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8vh 0vw;
}

.titulo{
    font-size: 1.7vw;
    line-height: 5vh;
    text-align: center;
    font-weight: bold;
    width: 40%;
}

.divContainer{
    display: flex;
    flex-direction: row;
}

.divIzq{
    display: flex;
    flex-direction: column;
    width: 35vw;
    padding: 0vh 1vw;
}

.divDer{
    display: flex;
    flex-direction: column;
    width: 35vw;
    padding: 0vh 1vw;
}

.text{
    font-size: 0.9vw;
    line-height: 3.2vh;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 3vh 0vw;
    }
    
    .titulo{
        font-size: 2.8vh;
        line-height: 5vh;
        text-align: center;
        font-weight: bold;
        width: 80%;
    }
    
    .divContainer{
        display: flex;
        flex-direction: column;
    }
    
    .divIzq{
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }
    
    .divDer{
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }
    
    .text{
        font-size: 1.8vh;
        line-height: 3.2vh;
    }
}

@media (max-width:650px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 3vh 0vw;
    }
    
    .titulo{
        font-size: 2.8vh;
        line-height: 5vh;
        text-align: center;
        font-weight: bold;
        width: 80%;
    }
    
    .divContainer{
        display: flex;
        flex-direction: column;
    }
    
    .divIzq{
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }
    
    .divDer{
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }
    
    .text{
        font-size: 1.8vh;
        line-height: 3.2vh;
    }
}