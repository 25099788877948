.navbarResp {
    display: none;
}

.divMenu {
    display: none;
}

.icon {
    display: none;
}

.navbarDerB {
    display: none;
}

.triangulo {
    display: none;
}

.link {
    display: none;
}

.text {
    display: none;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .navbarResp {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
    }

    .divMenu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .divMenu:hover{
        cursor: pointer;
    }

    .icon {
        display: flex;
        font-size: 30px;
        color: white;
    }

    .navbarDerB {
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 30vw;
        right: 0;
        top: 90%;
        border: solid #0c7ea3 0.1vh;
        background: #001332;
    }

    .triangulo {
        display: flex;
        position: absolute;
        top: 80%;
        height: 2vh;
        width: 2vh;
        transform: rotate(45deg);
        background: #001332;
        border-top: solid #0c7ea3 0.1vh;
        border-left: solid #0c7ea3 0.1vh;
        z-index: 1;
    }

    .link {
        display: flex;
        text-decoration: none;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        margin: 0;
        padding: 1vh 0vw;
        width: 30vw;
        height: 100%;
    }

    .text:hover{
        cursor: pointer;
        background: #226791;
        z-index: 1;
    }

    .divIdioma{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modalIdiomaFalse{
        display: none;
    }

    .modalIdiomaTrue{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30vw;
        background: #001c49;
    }
}

@media (max-width:650px) {
    .navbarResp {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
    }

    .divMenu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .divMenu:hover{
        cursor: pointer;
    }

    .icon {
        display: flex;
        font-size: 30px;
        color: white;
    }

    .navbarDerB {
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 45vw;
        right: 0;
        top: 90%;
        margin-right: -5vw;
        border: solid #0c7ea3 0.1vh;
        background: #001332;
    }

    .triangulo {
        display: flex;
        position: absolute;
        top: 80%;
        height: 2vh;
        width: 2vh;
        transform: rotate(45deg);
        background: #001332;
        border-top: solid #0c7ea3 0.1vh;
        border-left: solid #0c7ea3 0.1vh;
        z-index: 1;
    }

    .link {
        display: flex;
        text-decoration: none;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        margin: 0;
        padding: 1vh 0vw;
        width: 45vw;
        height: 100%;
        font-size: 4vw;
        height: 100%;
    }

    .text:hover{
        cursor: pointer;
        background: #226791;
        z-index: 1;
    }

    .divIdioma{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modalIdiomaFalse{
        display: none;
    }

    .modalIdiomaTrue{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45vw;
        background: #001c49;
    }
}