.presentacion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90vh;
    background-image: url(../../../utils/images/headbg.jpg);
    background-repeat: no-repeat;
    background-size: 100vw auto;
    background-position: top center;
}

.divText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
    margin-top: 15vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    opacity: 0;
    animation: moverText 1s forwards;
}

.divText2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.text {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 3vw;
    font-weight: 900;
    margin: 0;
}

.empresa {
    color: #30c4f9;
    font-size: 3vw;
    font-weight: 900;
    margin: 0;
    margin-left: 1.2vh;
}

.verProductosA {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 7.5vh;
    width: 20vw;
    border-radius: 1.7vh;
    font-size: 1vw;
    color: white;
    background: #30c4f9;
    opacity: 0;
    margin-top: 0vh;
    animation: moverBoton 1s forwards;
}

.verProductosB {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 7.5vh;
    width: 20vw;
    border-radius: 1.7vh;
    font-size: 1vw;
    color: white;
    background: #30c4f9;
    margin-top: 2.5vh;
}

.verProductosB:hover{
    animation: hoverProductos 0.3s forwards;
    cursor: pointer;
    opacity: 1;
    margin-top: 2.5vh;
}

.productosDestacados {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
    height: 20vh;
    opacity: 0;
    animation: aparecer 1s forwards;
}

.textDestacados {
    color: white;
}

.botonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9.1vh;
    width: 9.1vh;
    border-radius: 50%;
}

.botonContainer:hover{
    cursor: pointer;
}

.botonDestacadosFalse {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9vh;
    width: 9vh;
    background: #65d692;
    border-radius: 50%;
}

.botonDestacadosTrue{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9vh;
    width: 9vh;
    background: #65d692;
    border-radius: 50%;
    animation: hoverDestacados 0.3s forwards;
}

.arrow{
    color: white;
    font-size: 2vw;
}

.link{
    text-decoration: none;
}

@keyframes hoverProductos {
    100%{
        background: grey;
    }
}

@keyframes hoverDestacados {
    100%{
        height: 8vh;
        width: 8vh;
    }
}

@keyframes moverText {
    100%{
        padding-top: 10vh;
        padding-bottom: 0vh;
        opacity: 1;
    }
}

@keyframes moverBoton {
    100%{
        opacity: 1;
        margin-top: 2.5vh;
    }
}

@keyframes moverBotonMovil {
    100%{
        opacity: 1;
        margin-top: 0vh;
    }
}

@keyframes aparecer {
    100%{
        opacity: 1;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){

    .presentacion {
        height: 80vh;
    }
    
    .divText {
        margin-top: 5vh;
    }
    
    .text {
        font-size: 7vh;
    }
    
    .empresa {
        font-size: 7vh;
    }
    
    .verProductosA {
        font-size: 2vh;
        margin-top: 0vh;
    }
    
    .verProductosB {
        font-size: 3vh;
        margin-top: 0vh;
    }
    
    .verProductosB:hover{
        margin-top: 0vh;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .presentacion {
        height: 65vh;
        background-size: auto 100%;
        background-position: top center;
    }
    
    .divText {
        height: 16vh;
        margin-top: 10%;
    }
    
    .text {
        font-size: 7vw;
    }
    
    .empresa {
        font-size: 7vw;
    }

    .verProductosA {
        height: 6vh;
        width: 45vw;
        font-size: 2.6vw;
        animation: moverBotonMovil 1.4s forwards;
    }
    
    .verProductosB {
        height: 6vh;
        width: 45vw;
        font-size: 2.6vw;
        margin-top: 0;
    }
    
    .verProductosB:hover{
        margin-top: 0vh;
    }

    .productosDestacados {
        margin-bottom: 2vh;
    }

    .botonDestacadosFalse {
        height: 6.5vh;
        width: 6.5vh;
        background: #65d692;
        border-radius: 50%;
    }
    
    .botonDestacadosTrue{
        height: 6.5vh;
        width: 6.5vh;
        background: #65d692;
        border-radius: 50%;
        animation: none;
    }

    .textDestacados {
        font-size: 4vw;
        margin: 3vw;
    }
    
    .arrow{
        color: white;
        font-size: 3vh;
    }
}

@media (max-width:650px) {
    .presentacion {
        height: 65vh;
        background-size: auto 100%;
        background-position: top center;
    }
    
    .divText {
        height: 16vh;
        margin-top: 10%;
    }
    
    .text {
        font-size: 8vw;
    }
    
    .empresa {
        font-size: 8vw;
    }

    .verProductosA {
        height: 8vh;
        width: 45vw;
        font-size: 4vw;
        animation: moverBotonMovil 1.4s forwards;
    }
    
    .verProductosB {
        height: 8vh;
        width: 45vw;
        font-size: 4vw;
        margin-top: 0vh;
    }
    
    .verProductosB:hover{
        margin-top: 0vh;
    }

    .productosDestacados {
        margin-bottom: 2vh;
    }

    .botonDestacadosFalse {
        height: 7vh;
        width: 7vh;
        background: #65d692;
        border-radius: 50%;
    }
    
    .botonDestacadosTrue{
        height: 7vh;
        width: 7vh;
        background: #65d692;
        border-radius: 50%;
        animation: none;
    }

    .textDestacados {
        font-size: 4vw;
        margin: 3vw;
    }
    
    .arrow{
        color: white;
        font-size: 4vh;
    }
}