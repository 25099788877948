.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 5vh 0vw 0vh 0vw;
}

.titulo{
    font-size: 1.3vw;
    text-align: center;
    font-weight: bold;
}

.divCards{
    display: grid;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding-top: 8vh;
    grid-template-columns: auto auto auto auto;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 35vh;
    width: 15vw;
    padding: 1vh 0.5vw;
    margin-bottom: 5vh;
}

.imagen{
    height: 13vh;
}

.title{
    text-align: center;
    color: #5987f7;
    font-size: 1.1vw;
    font-weight: bold;
    margin-bottom: 0vh;
}

.text{
    text-align: center;
    color: #878d90;
    font-size: 0.9vw;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        padding: 3vh 0vw 0vh 0vw;
    }
    
    .titulo{
        font-size: 2.5vh;
        text-align: center;
        font-weight: bold;
    }
    
    .divCards{
        display: grid;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 0vh;
        grid-template-columns: auto auto;
    }
    
    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 35vh;
        width: 42vw;
        padding: 0vh 0.5vw;
        margin-bottom: 2vh;
    }
    
    .imagen{
        height: 13vh;
    }
    
    .title{
        text-align: center;
        color: #5987f7;
        font-size: 2.2vh;
        font-weight: bold;
        margin-bottom: 0vh;
    }
    
    .text{
        text-align: center;
        color: #878d90;
        font-size: 1.8vh;
    }
}

@media (max-width:650px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        padding: 3vh 0vw 0vh 0vw;
    }
    
    .titulo{
        font-size: 2.5vh;
        text-align: center;
        font-weight: bold;
    }
    
    .divCards{
        display: grid;
        align-items: center;
        justify-content: center;
        width: 90%;
        padding-top: 0vh;
        grid-template-columns: auto;
    }
    
    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 35vh;
        width: 80vw;
        padding: 1vh 0.5vw;
        margin-bottom: 0vh;
    }
    
    .imagen{
        height: 13vh;
    }
    
    .title{
        text-align: center;
        color: #5987f7;
        font-size: 2.2vh;
        font-weight: bold;
        margin-bottom: 0vh;
    }
    
    .text{
        text-align: center;
        color: #878d90;
        font-size: 1.8vh;
    }
}