.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
    background: #36497b;
}

.divIzq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 35vw;
}

.option {
    display: flex;
    align-items: center;
    height: 5vh;
    width: 15vw;
    margin: 1vh 0vw;
    padding: 0.2vh 1.5vw;
    font-weight: bold;
    color: white;
    border-radius: 1vh;
}

.optionHover {
    display: flex;
    align-items: center;
    height: 5vh;
    width: 15vw;
    margin: 1vh 0vw;
    padding: 0.2vh 1.5vw;
    font-weight: bold;
    color: white;
    border-radius: 1vh;
    background: #5987f7;
}

.optionHover:hover {
    cursor: pointer;
}

.divDer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 35vw;
}

.divText{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.divNone{
    display: none;
}

.title {
    color: white;
    font-size: 1.8vw;
    font-weight: bold;
    margin: 1vh 0vw;
}

.text {
    color: #f0f3fb;
    font-size: 1.1vw;
    opacity: 0.7;
    line-height: 3vh;
    margin: 1vh 0vw;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 70vh;
        width: 100%;
        padding: 1vh 0vw;
        background: #36497b;
    }
    
    .divIzq {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 35vw;
    }
    
    .option {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 18vw;
        margin: 1vh 0vw;
        padding: 0.2vh 1.5vw;
        font-size: 3.5vh;
        font-weight: bold;
        color: white;
        border-radius: 1vh;
    }
    
    .optionHover {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 18vw;
        margin: 1vh 0vw;
        padding: 1vh 1.5vw;
        font-size: 3.5vh;
        font-weight: bold;
        color: white;
        border-radius: 1vh;
        background: #5987f7;
    }
    
    .optionHover:hover {
        cursor: pointer;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 45vw;
    }
    
    .divText{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .divNone{
        display: none;
    }
    
    .title {
        color: white;
        font-size: 4vh;
        font-weight: bold;
        margin: 1vh 0vw;
    }
    
    .text {
        color: #f0f3fb;
        font-size: 3vh;
        opacity: 0.7;
        line-height: 3.5vh;
        margin: 1vh 0vw;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        background: #36497b;
    }
    
    .divIzq {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        padding: 3vh 0vw 1vh 0vw;
    }
    
    .option {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 50vw;
        margin: 1vh 0vw;
        padding: 0.2vh 1.5vw;
        font-weight: bold;
        color: white;
        border-radius: 1vh;
    }
    
    .optionHover {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 50vw;
        margin: 1vh 0vw;
        padding: 0.2vh 1.5vw;
        font-weight: bold;
        color: white;
        border-radius: 1vh;
        background: #5987f7;
    }
    
    .optionHover:hover {
        cursor: pointer;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 80vw;
        padding: 0vh 0vw 3vh 0vw;
    }
    
    .divText{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .divNone{
        display: none;
    }
    
    .title {
        color: white;
        font-size: 3vh;
        font-weight: bold;
        margin: 1vh 0vw;
    }
    
    .text {
        color: #f0f3fb;
        font-size: 1.8vh;
        opacity: 0.7;
        line-height: 3vh;
        margin: 1vh 0vw;
    }
}

@media (max-width:650px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        background: #36497b;
    }
    
    .divIzq {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        padding: 5vh 0vw;
    }
    
    .option {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 70vw;
        margin: 1vh 0vw;
        padding: 0.2vh 1.5vw;
        font-weight: bold;
        color: white;
        border-radius: 1vh;
    }
    
    .optionHover {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 70vw;
        margin: 1vh 0vw;
        padding: 0.2vh 1.5vw;
        font-weight: bold;
        color: white;
        border-radius: 1vh;
        background: #5987f7;
    }
    
    .optionHover:hover {
        cursor: pointer;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 80vw;
        padding: 0vh 0vw 5vh 0vw;
    }
    
    .divText{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .divNone{
        display: none;
    }
    
    .title {
        color: white;
        font-size: 3.5vh;
        font-weight: bold;
        margin: 1vh 0vw;
    }
    
    .text {
        color: #f0f3fb;
        font-size: 2.1vh;
        opacity: 0.7;
        line-height: 3vh;
        margin: 1vh 0vw;
    }
}