.container {
    display: grid;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    grid-template-columns: auto auto auto auto;
}

.imagen {
    max-height: 16vh;
    max-width: 10vw;
    margin: 5vh 0vw;
}

@media only screen and (min-width: 821px) and (max-width: 1150px) {
    
}

@media only screen and (min-width: 651px) and (max-width: 820px) {
    .container {
        display: grid;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        grid-template-columns: auto auto auto auto;
    }

    .imagen {
        max-height: 16vh;
        max-width: 15vw;
        margin: 3vh 0vw;
    }
}

@media (max-width:650px) {
    .container {
        display: grid;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        grid-template-columns: auto auto auto;
    }

    .imagen {
        max-height: 16vh;
        max-width: 20vw;
        margin: 3vh 0vw;
    }
}