.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.divContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45vh;
    margin: 5vh 0vw;
}

.divContainer2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45vh;
    width: 100%;
    padding: 5vh 0vw;
    background: #e7f4ff;
}

.imagen{
    width: 33vw;
    border-top-right-radius: 4vh;
    border-bottom-left-radius: 4vh;
}

.divText{
    width: 35vw;
    height: 85%;
    padding-left: 3vw;
}

.divText2{
    width: 35vw;
    height: 85%;
    padding-right: 3vw;
}

.title{
    font-size: 1.8vw;
    font-weight: bold;
}

.title2{
    font-size: 1.8vw;
    font-weight: bold;
    color: #7399f5;
}

.text{
    font-size: 0.9vw;
    color: grey;
    line-height: 3.3vh;
}

.text2{
    font-size: 0.9vw;
    color: grey;
    line-height: 3.3vh;
    color: #7fa4fd;
}

.verProductos{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    width: 13vw;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: white;
    background: #5987f7;
    font-weight: bold;
}

.verProductos:hover{
    animation: backgroundProductos 0.3s forwards;
    cursor: pointer;
}

.link{
    text-decoration: none;
}

@keyframes backgroundProductos {
    100%{
        background: grey;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    
    }
    
    .divContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 45vh;
        margin: 5vh 0vw;
    }
    
    .divContainer2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 45vh;
        width: 100%;
        padding: 5vh 0vw;
        background: #e7f4ff;
    }
    
    .imagen{
        width: 33vw;
        border-top-right-radius: 4vh;
        border-bottom-left-radius: 4vh;
    }
    
    .divText{
        width: 35vw;
        height: 85%;
        padding-left: 3vw;
    }
    
    .divText2{
        width: 35vw;
        height: 85%;
        padding-right: 3vw;
    }
    
    .title{
        font-size: 4vh;
        font-weight: bold;
    }
    
    .title2{
        font-size: 4vh;
        font-weight: bold;
        color: #7399f5;
    }
    
    .text{
        font-size: 2.5vh;
        color: grey;
        line-height: 3.8vh;
    }
    
    .text2{
        font-size: 2.5vh;
        color: grey;
        line-height: 3.8vh;
        color: #7fa4fd;
    }
    
    .verProductos{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 13vw;
        border-radius: 1.2vh;
        font-size: 1.8vh;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .divContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 100%;
        margin: 5vh 0vw;
    }
    
    .divContainer2{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding: 5vh 0vw;
        background: #e7f4ff;
    }
    
    .imagen{
        width: 70vw;
        border-top-right-radius: 4vh;
        border-bottom-left-radius: 4vh;
    }
    
    .divText{
        width: 70vw;
        height: auto;
        padding-left: 3vw;
    }
    
    .divText2{
        width: 70vw;
        height: auto;
        padding-right: 3vw;
    }
    
    .title{
        font-size: 3vh;
        font-weight: bold;
    }
    
    .title2{
        font-size: 3vh;
        font-weight: bold;
        color: #7399f5;
    }
    
    .text{
        font-size: 1.8vh;
        color: grey;
        line-height: 3.3vh;
    }
    
    .text2{
        font-size: 1.8vh;
        color: grey;
        line-height: 3.3vh;
        color: #7fa4fd;
    }
    
    .verProductos{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 50vw;
        margin-left: 10vw;
        border-radius: 1.2vh;
        font-size: 1.8vh;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }
}

@media (max-width:650px) {
    
    .divContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 100%;
        margin: 5vh 0vw;
    }
    
    .divContainer2{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding: 5vh 0vw;
        background: #e7f4ff;
    }
    
    .imagen{
        width: 80vw;
        border-top-right-radius: 4vh;
        border-bottom-left-radius: 4vh;
    }
    
    .divText{
        width: 80vw;
        height: auto;
        padding-left: 3vw;
    }
    
    .divText2{
        width: 80vw;
        height: auto;
        padding-right: 3vw;
    }
    
    .title{
        font-size: 3.2vh;
        font-weight: bold;
    }
    
    .title2{
        font-size: 3.2vh;
        font-weight: bold;
        color: #7399f5;
    }
    
    .text{
        font-size: 2vh;
        color: grey;
        line-height: 3.3vh;
    }
    
    .text2{
        font-size: 2vh;
        color: grey;
        line-height: 3.3vh;
        color: #7fa4fd;
    }
    
    .verProductos{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 70vw;
        margin-left: 5vw;
        border-radius: 1.2vh;
        font-size: 1.8vh;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }
}