.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.divContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45vh;
    margin: 5vh 0vw;
}

.divContainerA{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45vh;
    margin: 5vh 0vw;
}

.imagen{
    height: 42vh;
    border-top-right-radius: 4vh;
    border-bottom-left-radius: 4vh;
}

.divText{
    width: 35vw;
    padding-left: 3vw;
}

.divText2{
    width: 35vw;
    padding-right: 3vw;
}

.title{
    font-size: 1.8vw;
    font-weight: bold;
}

.text{
    font-size: 0.9vw;
    color: grey;
    line-height: 3vh;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .divContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 90vw;
        margin: 5vh 0vw;
    }
    
    .divContainerA{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 90vw;
        margin: 5vh 0vw;
    }
    
    .imagen{
        height: 42vh;
        border-top-right-radius: 4vh;
        border-bottom-left-radius: 4vh;
    }
    
    .divText{
        width: 50vw;
        padding-left: 3vw;
    }
    
    .divText2{
        width: 50vw;
        padding-right: 3vw;
    }
    
    .title{
        font-size: 4.5vh;
        font-weight: bold;
    }
    
    .text{
        font-size: 2.8vh;
        color: grey;
        line-height: 3vh;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .divContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin: 5vh 0vw;
    }

    .divContainerA{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: auto;
        margin: 5vh 0vw;
    }
    
    .imagen{
        height: auto;
        width: 80vw;
        border-top-right-radius: 4vh;
        border-bottom-left-radius: 4vh;
    }
    
    .divText{
        width: 80vw;
        padding-left: 3vw;
    }
    
    .divText2{
        width: 80vw;
        padding-right: 3vw;
    }
    
    .title{
        font-size: 3.6vh;
        font-weight: bold;
    }
    
    .text{
        font-size: 1.8vh;
        color: grey;
        line-height: 3vh;
    }
}

@media (max-width:650px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .divContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin: 5vh 0vw;
    }

    .divContainerA{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: auto;
        margin: 5vh 0vw;
    }
    
    .imagen{
        height: auto;
        width: 80vw;
        border-top-right-radius: 4vh;
        border-bottom-left-radius: 4vh;
    }
    
    .divText{
        width: 80vw;
        padding-left: 3vw;
    }
    
    .divText2{
        width: 80vw;
        padding-right: 3vw;
    }
    
    .title{
        font-size: 3.6vh;
        font-weight: bold;
    }
    
    .text{
        font-size: 1.8vh;
        color: grey;
        line-height: 3vh;
    }
}