.app{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

img{
    -webkit-user-drag: none;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .app{
        user-select: none;
    }
}

@media (max-width:650px) {
    .app{
        user-select: none;
    }
}