.servicios{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10vh 0vw;
    background: #f3f9ff;
}

.divText{
    display: flex;
    flex-direction: column;
    margin-left: 14vw;
    width: 40vw;
}

.text1{
    font-size: 1.5vw;
    margin: 0.5vh 0vw;
    color: rgb(76, 76, 76);
}

.text2{
    font-size: 1.5vw;
    margin: 0.5vh 0vw;
}

.divButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 22vw;
    margin-right: 14vw;
}

.link{
    text-decoration: none;
}

.contacto{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: white;
    background: #5987f7;
    font-weight: bold;
    white-space: nowrap;
    padding: 0vh 1.5vw;
}

.contacto:hover{
    cursor: pointer;
    animation: backgroundServicios 0.3s forwards;
}

@keyframes backgroundServicios {
    100%{
        background: grey;
    }
}

@keyframes backgroundContactanos {
    100%{
        background: #f7f7f7;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .servicios{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10vh 0vw;
        background: #f3f9ff;
    }
    
    .divText{
        display: flex;
        flex-direction: column;
        margin-left: 10vw;
    }
    
    .text1{
        font-size: 4vh;
        margin: 0.5vh 0vw;
        color: rgb(76, 76, 76);
    }
    
    .text2{
        font-size: 4vh;
        margin: 0.5vh 0vw;
    }

    .divButton{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 22vw;
        margin-right: 10vw;
    }
    
    .contacto{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        border-radius: 1.2vh;
        font-size: 2.5vh;
        color: white;
        background: #5987f7;
        font-weight: bold;
        white-space: nowrap;
        padding: 0vh 1.5vw;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .servicios{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5vh 0vw;
        background: #f3f9ff;
    }
    
    .divText{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0vw;
        width: 80vw;
    }
    
    .text1{
        font-size: 2.5vh;
        text-align: center;
        margin: 0.3vh 0vw;
        color: gray;
    }
    
    .text2{
        font-size: 2.5vh;
        text-align: center;
        margin: 0.3vh 0vw;
        color: rgb(83, 83, 83);
    }
    
    .divButton{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        margin-right: 0vw;
    }
    
    .contacto{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: auto;
        padding: 0vh 2vw;
        border-radius: 1.2vh;
        margin-top: 3vh;
        font-size: 1.5vh;
    }
}

@media (max-width:650px) {
    .servicios{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5vh 0vw;
        background: #f3f9ff;
    }
    
    .divText{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0vw;
        width: 80vw;
    }
    
    .text1{
        font-size: 2.5vh;
        text-align: center;
        margin: 0.3vh 0vw;
        color: gray;
    }
    
    .text2{
        font-size: 2.5vh;
        text-align: center;
        margin: 0.3vh 0vw;
        color: rgb(83, 83, 83);
    }
    
    .divButton{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        margin-right: 0vw;
    }
    
    .contacto{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: auto;
        padding: 0vh 2vw;
        border-radius: 1.2vh;
        margin-top: 3vh;
        font-size: 1.5vh;
    }
}