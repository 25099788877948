.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 55vh;
    width: 100%;
    padding-top: 15vh;
    padding-bottom: 5vh;
    background: #29304a;
}

.divIzq {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vh;
    width: 35%;
    margin-right: 1vw;
}

.circulosContenedor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 65vh;
    border-radius: 50%;
    margin-top: -3vh;
}


.circulo2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53vh;
    width: 53vh;
    border-radius: 50%;
    background-color: #2c3350;
    animation: circulo2False 0.8s forwards;
}


.circulo2Hover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 65vh;
    border-radius: 50%;
    background-color: #2c3350;
    animation: circulo2True 0.8s forwards;
}

.circulo1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46vh;
    width: 46vh;
    border-radius: 50%;
    background-color: #32375a;
    animation: circulo1False 0.8s forwards;
}

.circulo1Hover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vh;
    width: 55vh;
    border-radius: 50%;
    background-color: #32375a;
    animation: circulo1True 0.8s forwards;
}

.circuloImagen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
    width: 40vh;
    border-radius: 50%;
    background: linear-gradient(120deg, #0397f0, #0ddcc7);
    animation: circuloImagenFalse 0.8s forwards;
}

.circuloImagenHover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45vh;
    width: 45vh;
    border-radius: 50%;
    background: linear-gradient(120deg, #0397f0, #0ddcc7);
    animation: circuloImagenTrue 0.8s forwards;
}

.imagen{
    height: 30vh;
    margin-right: 5vh;
    -webkit-user-drag: none;
    animation: imagenFalse 0.8s forwards;
}

.imagenHover{
    height: 35vh;
    margin-right: 5vh;
    -webkit-user-drag: none;
    animation: imagenTrue 0.8s forwards;
}

.divDer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    width: 35%;
    padding-top: 3vh;
    padding-bottom: 5vh;
    margin-left: 1vw;
}

.titulo{
    color: #1cc6f3;
    font-size: 1.3vw;
    font-weight: bold;
    margin: 1vh 0vw;
}

.text1{
    color: white;
    font-size: 1.7vw;
    font-weight: bold;
    line-height: 4.5vh;
    margin: 1vh 0vw;
}

.text2{
    color: white;
    font-size: 1.2vw;
    line-height: 4vh;
    margin: 1vh 0vw;
}

@keyframes circulo2True {
    10% {
        height: 65vh;
        width: 65vh;
    }
    80% {
        height: 50vh;
        width: 50vh;
    }
    100% {
        height: 53vh;
        width: 53vh;
    }
}

@keyframes circulo1True {
    60% {
        height: 45vh;
        width: 45vh;
    }
    85% {
        height: 46vh;
        width: 46vh;
    }
    100% {
        height: 46vh;
        width: 46vh;
    }
}

@keyframes circuloImagenTrue {
    100% {
        height: 40vh;
        width: 40vh;
    }
}

@keyframes imagenTrue {
    100%{
        height: 30vh;
        margin-right: 5vh;
    }
}

@keyframes circulo2False {
    20% {
        height: 53vh;
        width: 53vh;
    }
    100% {
        height: 65vh;
        width: 65vh;
    }
}

@keyframes circulo1False {
    10% {
        height: 46vh;
        width: 46vh;
    }
    100% {
        height: 55vh;
        width: 55vh;
    }
}

@keyframes circuloImagenFalse {
    0% {
        height: 40vh;
        width: 40vh;
    }
    100% {
        height: 45vh;
        width: 45vh;
    }
}

@keyframes imagenFalse {
    100%{
        height: 35vh;
        margin-right: 5vh;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-top: 15vh;
        padding-bottom: 5vh;
        background: #29304a;
    }
    
    .divIzq {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 80%;
        margin-right: 0vw;
    }
    
    .circulosContenedor {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45vh;
        width: 45vh;
        border-radius: 50%;
        margin-top: -3vh;
    }
    
    
    .circulo2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 33vh;
        width: 33vh;
        border-radius: 50%;
        background-color: #2c3350;
        animation: circulo2False 0.8s forwards;
    }
    
    
    .circulo2Hover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45vh;
        width: 45vh;
        border-radius: 50%;
        background-color: #2c3350;
        animation: circulo2True 0.8s forwards;
    }
    
    .circulo1 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26vh;
        width: 26vh;
        border-radius: 50%;
        background-color: #32375a;
        animation: circulo1False 0.8s forwards;
    }
    
    .circulo1Hover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38vh;
        width: 38vh;
        border-radius: 50%;
        background-color: #32375a;
        animation: circulo1True 0.8s forwards;
    }
    
    .circuloImagen {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25vh;
        width: 25vh;
        border-radius: 50%;
        -webkit-user-drag: none;
        animation: circuloImagenFalse 0.8s forwards;
    }
    
    .circuloImagenHover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31vh;
        width: 31vh;
        border-radius: 50%;
        -webkit-user-drag: none;
        animation: circuloImagenTrue 0.8s forwards;
    }

    .imagen{
        height: 22vh;
        margin-right: 5vh;
        -webkit-user-drag: none;
        animation: imagenFalse 0.8s forwards;
    }
    
    .imagenHover{
        height: 27vh;
        margin-right: 6vh;
        -webkit-user-drag: none;
        animation: imagenTrue 0.8s forwards;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        width: 80%;
        padding-top: 2vh;
        padding-bottom: 0vh;
        margin-left: 1vw;
    }
    
    .titulo{
        color: #1cc6f3;
        font-size: 4vw;
        font-weight: bold;
        margin: 1vh 0vw;
    }
    
    .text1{
        color: white;
        font-size: 3.5vw;
        font-weight: bold;
        line-height: 3vh;
        margin: 1vh 0vw;
    }
    
    .text2{
        color: white;
        font-size: 3vw;
        line-height: 3vh;
        margin: 1vh 0vw;
    }

    @keyframes circulo2True {
        10% {
            height: 45vh;
            width: 45vh;
        }
        80% {
            height: 32vh;
            width: 32vh;
        }
        100% {
            height: 33vh;
            width: 33vh;
        }
    }
    
    @keyframes circulo1True {
        60% {
            height: 29vh;
            width: 29vh;
        }
        85% {
            height: 29vh;
            width: 29vh;
        }
        100% {
            height: 29vh;
            width: 29vh;
        }
    }
    
    @keyframes circuloImagenTrue {
        100% {
            height: 25vh;
            width: 25vh;
        }
    }
    
    @keyframes circulo2False {
        20% {
            height: 33vh;
            width: 33vh;
        }
        100% {
            height: 45vh;
            width: 45vh;
        }
    }
    
    @keyframes circulo1False {
        10% {
            height: 28vh;
            width: 28vh;
        }
        100% {
            height: 38vh;
            width: 38vh;
        }
    }
    
    @keyframes circuloImagenFalse {
        0% {
            height: 25vh;
            width: 25vh;
        }
        100% {
            height: 31vh;
            width: 31vh;
        }
    }

    @keyframes imagenTrue {
        100%{
            height: 22vh;
            margin-right: 5vh;
        }
    }
    
    
    @keyframes imagenFalse {
        100%{
            height: 27vh;
            margin-right: 6vh;
        }
    }
}

@media (max-width:650px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-top: 15vh;
        padding-bottom: 5vh;
        background: #29304a;
    }
    
    .divIzq {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 80%;
        margin-right: 0vw;
    }
    
    .circulosContenedor {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45vh;
        width: 45vh;
        border-radius: 50%;
        margin-top: -3vh;
    }
    
    
    .circulo2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 33vh;
        width: 33vh;
        border-radius: 50%;
        background-color: #2c3350;
        animation: circulo2False 0.8s forwards;
    }
    
    
    .circulo2Hover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45vh;
        width: 45vh;
        border-radius: 50%;
        background-color: #2c3350;
        animation: circulo2True 0.8s forwards;
    }
    
    .circulo1 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26vh;
        width: 26vh;
        border-radius: 50%;
        background-color: #32375a;
        animation: circulo1False 0.8s forwards;
    }
    
    .circulo1Hover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38vh;
        width: 38vh;
        border-radius: 50%;
        background-color: #32375a;
        animation: circulo1True 0.8s forwards;
    }
    
    .circuloImagen {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25vh;
        width: 25vh;
        border-radius: 50%;
        -webkit-user-drag: none;
        animation: circuloImagenFalse 0.8s forwards;
    }
    
    .circuloImagenHover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31vh;
        width: 31vh;
        border-radius: 50%;
        -webkit-user-drag: none;
        animation: circuloImagenTrue 0.8s forwards;
    }

    .imagen{
        height: 22vh;
        margin-right: 5vh;
        -webkit-user-drag: none;
        animation: imagenFalse 0.8s forwards;
    }
    
    .imagenHover{
        height: 27vh;
        margin-right: 6vh;
        -webkit-user-drag: none;
        animation: imagenTrue 0.8s forwards;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        width: 80%;
        padding-top: 2vh;
        padding-bottom: 0vh;
        margin-left: 1vw;
    }
    
    .titulo{
        color: #1cc6f3;
        font-size: 5vw;
        font-weight: bold;
        margin: 1vh 0vw;
    }
    
    .text1{
        color: white;
        font-size: 4.5vw;
        font-weight: bold;
        line-height: 3vh;
        margin: 1vh 0vw;
    }
    
    .text2{
        color: white;
        font-size: 4vw;
        line-height: 3vh;
        margin: 1vh 0vw;
    }

    @keyframes circulo2True {
        10% {
            height: 45vh;
            width: 45vh;
        }
        80% {
            height: 32vh;
            width: 32vh;
        }
        100% {
            height: 33vh;
            width: 33vh;
        }
    }
    
    @keyframes circulo1True {
        60% {
            height: 29vh;
            width: 29vh;
        }
        85% {
            height: 29vh;
            width: 29vh;
        }
        100% {
            height: 29vh;
            width: 29vh;
        }
    }
    
    @keyframes circuloImagenTrue {
        100% {
            height: 25vh;
            width: 25vh;
        }
    }
    
    @keyframes circulo2False {
        20% {
            height: 33vh;
            width: 33vh;
        }
        100% {
            height: 45vh;
            width: 45vh;
        }
    }
    
    @keyframes circulo1False {
        10% {
            height: 28vh;
            width: 28vh;
        }
        100% {
            height: 38vh;
            width: 38vh;
        }
    }
    
    @keyframes circuloImagenFalse {
        0% {
            height: 25vh;
            width: 25vh;
        }
        100% {
            height: 31vh;
            width: 31vh;
        }
    }

    @keyframes imagenTrue {
        100%{
            height: 22vh;
            margin-right: 5vh;
        }
    }
    
    
    @keyframes imagenFalse {
        100%{
            height: 27vh;
            margin-right: 6vh;
        }
    }
}