.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: -10vh;
    height: 8vh;
    width: 80vw;
    padding: 1vh 1vw;
    background: transparent;
    animation: navbar 0.3s forwards;
}

.navbarIzq {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30vw;
}

.logo {
    height: 7.5vh;
}

.navbarDerA {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70vw;
}

.text {
    margin: 0vh 1vw;
    color: white;
    font-size: 0.9vw;
    font-weight: bold;
    user-select: none;
    white-space: nowrap;
}

.text:hover {
    text-shadow: 0vh 0vh 1.5vh white;
    cursor: pointer;
}

.divIdioma {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalIdiomaTrue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: solid #0c7ea3 0.1vh;
    background: #001332;
    margin-top: 6vh;
    width: 7vw;
}

.modalIdiomaFalse {
    display: none;
}

.trianguloTrue {
    position: absolute;
    margin-top: 5vh;
    height: 2vh;
    width: 2vh;
    transform: rotate(45deg);
    background: #001332;
    border-top: solid #0c7ea3 0.1vh;
    border-left: solid #0c7ea3 0.1vh;
    z-index: 1;
}

.trianguloFalse {
    display: none;
}

.idiomaOption {
    color: white;
    text-align: center;
    width: 100%;
    margin: 0vh 1vw;
    padding: 1vh 0vw;
    font-size: 0.9vw;
}

.idiomaOption:hover {
    cursor: pointer;
    background: #226791;
    z-index: 1;
}

.goTop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 5vh;
    width: 5vh;
    bottom: 3vh;
    right: 3vh;
    background: white;
    border: solid 0.1vh;
    border-radius: 0.7vh;
}

.goTop:hover {
    cursor: pointer;
    background: rgb(223, 223, 223);
    box-shadow: 0 0 1vh white;
}

.arrow {
    font-size: 2.6vh;
}

.link {
    text-decoration: none;
}

@keyframes navbar {
    100% {
        top: 0vh
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .navbar {
        width: 90vw;
    }
    
    .logo {
        height: 10vh;
    }
    
    .navbarDerA {
        width: 70vw;
    }
    
    .text {
        font-size: 2.5vh;
    }
    
    .modalIdiomaTrue {
        width: 9vw;
    }
    
    .idiomaOption {
        color: white;
        text-align: center;
        width: 100%;
        margin: 0vh 1vw;
        padding: 1vh 0vw;
        font-size: 2.5vh;
    }
    
    .idiomaOption:hover {
        cursor: pointer;
        background: #226791;
        z-index: 1;
    }
    
    .goTop {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        height: 8vh;
        width: 8vh;
        bottom: 3vh;
        right: 3vh;
        background: white;
        border: solid 0.1vh;
        border-radius: 0.7vh;
    }
    
    .arrow {
        font-size: 4vh;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){

    .navbar{
        width: 80vw;
    }

    .navbarIzq {
        width: auto;
    }
    
    .logo {
        height: auto;
        width: 30vw;
    }

    .navbarDerA {
        display: none;
    }
}

@media (max-width:650px) {

    .navbar{
        width: 80vw;
    }

    .navbarIzq {
        width: auto;
    }
    
    .logo {
        height: auto;
        width: 45vw;
    }

    .navbarDerA {
        display: none;
    }
}