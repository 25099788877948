.servicios {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10vh 0vw;
    background: #f3f9ff;
}

.divText {
    display: flex;
    flex-direction: column;
    margin-left: 14vw;
}

.text1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2vw;
    margin: 0vh 0vw;
    color: rgb(76, 76, 76);
    white-space: nowrap;
}

.nombreCurso {
    font-size: 1.2vw;
    margin: 0.5vh 0vw;
    color: rgb(76, 76, 76);
    font-weight: bold;
}

.text2 {
    font-size: 1.2vw;
    margin: 2.5vh 0vw 0.5vh 0vw;
    color: rgb(41, 41, 41);
}

.divButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 14vw;
}

.verServicios {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14vw;
    height: 5vh;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: white;
    background: #5987f7;
    font-weight: bold;
    white-space: nowrap;
    margin-right: 1vw;
}

.verServicios:hover {
    cursor: pointer;
    animation: backgroundServicios 0.3s forwards;
}

.contactanos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13vw;
    height: 5vh;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: black;
    background: white;
    font-weight: bold;
    box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    white-space: nowrap;
}

.contactanos:hover {
    cursor: pointer;
    animation: backgroundContactanos 0.3s forwards;
}

.link {
    text-decoration: none;
}

@keyframes backgroundServicios {
    100% {
        background: grey;
    }
}

@keyframes backgroundContactanos {
    100% {
        background: #f7f7f7;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px) {}

@media only screen and (min-width: 651px) and (max-width: 820px) {
    .servicios {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 3vh 0vw;
        background: #f3f9ff;
    }

    .divText {
        display: flex;
        flex-direction: column;
        margin-left: 0vw;
        width: 85%;
    }

    .text1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 3.5vw;
        margin: 0vh 0vw;
        color: rgb(76, 76, 76);
        white-space: nowrap;
    }

    .nombreCurso {
        font-size: 3.5vw;
        margin: 0.5vh 0vw;
        color: rgb(76, 76, 76);
        font-weight: bold;
    }

    .text2 {
        font-size: 3vw;
        margin: 1.5vh 0vw 0.5vh 0vw;
        color: rgb(41, 41, 41);
    }

    .divButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        margin-right: 0vw;
        margin-top: 3vh;
    }

    .verServicios {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 40vw;
        border-radius: 1.2vh;
        margin-top: 2vh;
        font-size: 1.5vh;
    }

    .contactanos {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 40vw;
        border-radius: 1.2vh;
        margin-top: 2vh;
        font-size: 1.5vh;
    }
}

@media (max-width:650px) {
    .servicios {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 3vh 0vw;
        background: #f3f9ff;
    }

    .divText {
        display: flex;
        flex-direction: column;
        margin-left: 0vw;
        width: 80%;
    }

    .text1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 4vw;
        margin: 0vh 0vw;
        color: rgb(76, 76, 76);
        white-space: nowrap;
    }

    .nombreCurso {
        font-size: 4vw;
        margin: 0.5vh 0vw;
        color: rgb(76, 76, 76);
        font-weight: bold;
    }

    .text2 {
        font-size: 4vw;
        margin: 1.5vh 0vw 0.5vh 0vw;
        color: rgb(41, 41, 41);
    }

    .divButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
        margin-right: 0vw;
        margin-top: 3vh;
    }

    .verServicios {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 60vw;
        border-radius: 1.2vh;
        margin-top: 2vh;
        font-size: 1.8vh;
    }

    .contactanos {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 60vw;
        border-radius: 1.2vh;
        margin-top: 2vh;
        font-size: 1.8vh;
    }
}