.contactos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40vh;
    width: 70%;
    padding: 0vh 15%;
    background: #f3f9ff;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 22vh;
    width: 18vw;
    padding: 4vh 2vw;
    background: white;
    border: solid 0.1vh rgb(237, 237, 237);
}

.card:hover {
    border: solid 0.1vh silver;
}

.divTitulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.divIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    width: 6vh;
    border-radius: 50%;
    background: grey;
}

.divIconHover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    width: 6vh;
    border-radius: 50%;
    background: grey;
    animation: cambiarColor 0.3s forwards;
}

.icon {
    font-size: 1.5vw;
    color: white;
}

.titulo {
    font-size: 1.5vw;
    margin: 0;
    margin-left: 1vw;
    font-weight: bold;
    color: #4072f8;
}

.text1 {
    color: grey;
    margin: 0;
}

.text2 {
    font-size: 1.5vw;
    margin: 0 0 0 1vw;
    color: grey;
    width: 100%;
}

.text2Hover {
    font-size: 1.5vw;
    margin: 0 0 0 1vw;
    color: black;
    width: 100%;
}

.text2Hover:hover {
    cursor: pointer;
}

.text2Map {
    font-size: 1.5vw;
    margin: 0 0 0 1vw;
    color: black;
    width: 100%;
}

@keyframes cambiarColor {
    100% {
        background: #4072f8;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .contactos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: auto;
        width: 80%;
        padding: 5vh 10%;
        background: #f3f9ff;
    }
    
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 30vh;
        width: 21vw;
        padding: 3vh 2vw 4vh 2vw;
        background: white;
        border: solid 0.1vh rgb(237, 237, 237);
    }
    
    .divTitulo {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    
    .divIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6vh;
        width: 6vh;
        border-radius: 50%;
        background: grey;
    }
    
    .divIconHover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6vh;
        width: 6vh;
        border-radius: 50%;
        background: grey;
        animation: cambiarColor 0.3s forwards;
    }
    
    .icon {
        font-size: 3vh;
        color: white;
    }
    
    .titulo {
        font-size: 2.5vh;
        margin: 0;
        margin-left: 1vw;
        font-weight: bold;
        color: #4072f8;
    }
    
    .text1 {
        font-size: 3vh;
        color: grey;
        margin-top: 1vh;
    }
    
    .text2 {
        font-size: 3vh;
        margin: 2vh 0 0 0vw;
        color: grey;
        width: 100%;
    }
    
    .text2Hover {
        font-size: 3vh;
        margin: 0 0 0 1vw;
        color: black;
        width: 100%;
    }
    
    .text2Hover:hover {
        cursor: pointer;
    }
    
    .text2Map {
        font-size: 3vh;
        margin: 0 0 0 1vw;
        color: black;
        width: 100%;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .contactos {
        flex-direction: column;
        height: auto;
        width: 90vw;
        padding: 3vh 5vw;
    }

    .card {
        height: 20vh;
        width: 80vw;
        padding: 4vh 5vw;
        margin: 1vh 0vw;
    }

    .titulo {
        margin-left: 3vw;
    }

    .text1 {
        color: grey;
        margin: 0;
        font-size: 2vh;
    }

    .text2 {
        font-size: 2.5vh;
        margin: 0 0 0 0vw;
        color: grey;
        width: 100%;
    }

    .text2Hover {
        font-size: 3vh;
        margin: 0 0 0 1vw;
        color: black;
        width: 100%;
    }

    .text2Map {
        font-size: 3vh;
        margin: 0 0 0 1vw;
        color: black;
        width: 100%;
    }
    
    .icon {
        font-size: 3vh;
        color: white;
    }
}

@media (max-width:650px) {
    .contactos {
        flex-direction: column;
        height: auto;
        width: 90vw;
        padding: 3vh 5vw;
    }

    .card {
        height: 23vh;
        width: 80vw;
        padding: 2.5vh 5vw;
        margin: 1vh 0vw;
    }

    .titulo {
        margin-left: 3vw;
        font-size: 3vh;
    }

    .text1 {
        color: grey;
        margin: 0;
        font-size: 2.2vh;
    }

    .text2 {
        font-size: 2.8vh;
        margin: 0 0 0 1vw;
        color: grey;
        width: 100%;
    }

    .text2Hover {
        font-size: 3vh;
        margin: 0 0 0 1vw;
        color: black;
        width: 100%;
    }

    .text2Map {
        font-size: 3vh;
        margin: 0 0 0 1vw;
        color: black;
        width: 100%;
    }
    
    .icon {
        font-size: 3vh;
        color: white;
    }
}