.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8vh 0vw;
}

.titulo{
    font-size: 1.1vw;
    margin-bottom: 4vh;
    font-weight: bold;
    white-space: nowrap;
}

.productos{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 73%;
}

.card{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 22vh;
    width: 35vw;
    border: solid #e0e0e8 0.1vh;
    border-radius: 1vh;
    background: white;
}

.imagen{
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: -4vw;
    height: 11vw;
}

.divTextCard{
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 22vw;
    margin-left: 9vw;
}

.divTextCard:hover{
    cursor: pointer;
}

.divTextArrow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
}

.textCard1{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vh;
    font-size: 1vw;
    font-weight: bold;
    height: 3vh;
    color: black;
    width: 20vw;
}

.arrowFalse{
    display: flex;
    margin-left: 0.2vw; 
    font-size: 1vw;
    color: black;
}

.arrowTrue{
    display: flex;
    margin-left: 0.2vw;
    font-size: 1vw;
    animation: girarArrow 1s forwards;
    color: black;
}

.textCard2{
    margin: 1vh;
    font-size: 1vw;
    color: grey;
}

.link{
    text-decoration: none;
}

@keyframes girarArrow {
    100%{
        transform: rotateX(180deg);
        font-size: 1vw;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        padding-top: 0vh;
        padding-bottom: 4vh;
    }

    .productos{
        flex-direction: column;
    }

    .divText1 {
        width: 80vw;
    }

    .text1 {
        font-size: 2.2vh;
    }

    .text2 {
        font-size: 2.2vh;
    }

    .divCards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin-top: 2vh;
    }

    .cards1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
    }

    .cards2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
    }

    .card {
        height: 20vh;
        width: 80vw;
        border: solid silver 0.1vh;
        border-radius: 2vh;
        background: white;
        margin: 1vh 0vw;
        padding: 1vh 0vw 1vh 0vw;
    }

    .imagen {
        margin-left: -12vw;
        height: 25vw;
    }

    .divTextCard {
        flex-direction: column;
        height: 20vh;
        width: 58vw;
        margin-left: 18vw;
    }

    .textCard1 {
        margin: 1vh 0vw 0vh 0vw;
        font-size: 1.9vh;
        height: 3vh;
        width: 58vw;
    }

    .arrowFalse {
        display: none;
        margin-left: 0.2vw;
        font-size: 20px;
    }

    .arrowTrue {
        display: none;
        margin-left: 0.2vw;
        width: 20px;
        font-size: 20px;
        animation: girarArrow 1s forwards;
        color: black;
    }

    .textCard2 {
        margin: 2vh 2vw 0vh 0vw;
        font-size: 1.9vh;
        color: grey;
        width: 55vw;
    }

    .otrosProductos {
        flex-direction: column;
        width: 80vw;
        margin-top: 0vh;
        padding: 5vh 0vw 3vh 0vw;
    }

    .divTextProd {
        display: flex;
        flex-direction: column;
    }

    .textProd1 {
        margin: 0.5vh;
        font-size: 4.5vw;
        color: rgb(113, 113, 113);
        text-align: center;
    }

    .textProd2 {
        margin: 0.5vh;
        font-size: 2.8vh;
        font-weight: 100;
        text-align: center;
    }

    .divButtonProd {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3vh 0vw;
        height: 12vh;
        width: 100%;
    }

    .verProductos {
        height: 5vh;
        width: 40vw;
        border-radius: 1vh;
        font-size: 1.5vh;
        text-align: center;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }

    .consultas {
        height: 5vh;
        width: 40vw;
        border-radius: 1vh;
        font-size: 1.5vh;
        text-align: center;
        color: black;
        background: white;
        font-weight: bold;
        box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    }
}

@media (max-width:650px) {
    .container {
        padding-top: 0vh;
        padding-bottom: 4vh;
    }

    .productos{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 73%;
    }

    .divText1 {
        width: 80vw;
    }

    .text1 {
        font-size: 2.2vh;
    }

    .text2 {
        font-size: 2.2vh;
    }

    .divCards {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin-top: 2vh;
    }

    .cards1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
    }

    .cards2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80vw;
    }

    .card {
        height: 24vh;
        width: 80vw;
        border: solid silver 0.1vh;
        border-radius: 2vh;
        background: white;
        margin: 1vh 0vw;
    }

    .imagen {
        margin-left: -15vw;
        height: 30vw;
    }

    .divTextCard {
        flex-direction: column;
        height: 20vh;
        width: 55vw;
        margin-left: 22vw;
    }

    .textCard1 {
        margin: 1vh 0vw 0vh 0vw;
        font-size: 1.9vh;
        height: 3vh;
        width: 55vw;
    }

    .arrowFalse {
        display: none;
        margin-left: 0.2vw;
        font-size: 20px;
    }

    .arrowTrue {
        display: none;
        margin-left: 0.2vw;
        width: 20px;
        font-size: 20px;
        animation: girarArrow 1s forwards;
        color: black;
    }

    .textCard2 {
        margin: 2vh 2vw 0vh 0vw;
        font-size: 1.9vh;
        color: grey;
        width: 55vw;
    }

    .otrosProductos {
        flex-direction: column;
        width: 80vw;
        margin-top: 0vh;
        padding: 5vh 0vw 3vh 0vw;
    }

    .divTextProd {
        display: flex;
        flex-direction: column;
    }

    .textProd1 {
        margin: 0.5vh;
        font-size: 6vw;
        color: rgb(113, 113, 113);
        text-align: center;
    }

    .textProd2 {
        margin: 0.5vh;
        font-size: 2.8vh;
        font-weight: 100;
        text-align: center;
    }

    .divButtonProd {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3vh 0vw;
        height: 12vh;
        width: 100%;
    }

    .verProductos {
        height: 5vh;
        width: 50vw;
        border-radius: 1vh;
        font-size: 1.8vh;
        text-align: center;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }

    .consultas {
        height: 5vh;
        width: 50vw;
        border-radius: 1vh;
        font-size: 1.8vh;
        text-align: center;
        color: black;
        background: white;
        font-weight: bold;
        box-shadow: 0vh 0vh 1vh rgb(191, 191, 191);
    }
}