.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 22vh;
    width: 100%;
    padding-top: 15vh;
    background-image: url(../../../utils/images/headbg.jpg);
    background-repeat: no-repeat;
    background-size: 100vw auto;
    background-position: top center;
}

.title{
    color: white;
    font-size: 1.6vw;
    font-weight: bold;
    margin: 1.2vh 0vw;
}

.text{
    width: 60%;
    font-size: 1.4vw;
    text-align: center;
    color: #a8b3cd;
    line-height: 4vh;
}

.divIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 32.5vh;
    height: 5vw;
    width: 5vw;
    border-radius: 50%;
    background: #00c6fb;
}

.icon{
    color: white;
    font-size: 2.5vw;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 30vh;
        width: 100%;
        padding-top: 20vh;
        background-image: url(../../../utils/images/headbg.jpg);
        background-repeat: no-repeat;
        background-size: 100vw auto;
        background-position: top center;
    }
    
    .title{
        color: white;
        font-size: 4vh;
        font-weight: bold;
        margin: 1.2vh 0vw;
    }
    
    .text{
        width: 50%;
        font-size: 1.7vw;
        text-align: center;
        color: #a8b3cd;
        line-height: 4vh;
    }

    .divIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 42.5vh;
        height: 7vw;
        width: 7vw;
        border-radius: 50%;
        background: #00c6fb;
    }
    
    .icon{
        color: white;
        font-size: 4vw;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 18vh;
        width: 100%;
        padding-top: 12vh;
        background-image: url(../../../utils/images/headbg.jpg);
        background-repeat: no-repeat;
        background-size: 100vw auto;
        background-position: top center;
    }
    
    .title{
        color: white;
        font-size: 2.2vh;
        font-weight: bold;
        margin: 1.2vh 0vw;
    }
    
    .text{
        width: 70%;
        font-size: 2.5vw;
        text-align: center;
        color: #a8b3cd;
        line-height: normal;
    }

    .divIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 26vh;
        height: 12vw;
        width: 12vw;
        border-radius: 50%;
        background: #00c6fb;
    }
    
    .icon{
        color: white;
        font-size: 7vw;
    }
}

@media (max-width:650px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 20vh;
        width: 100%;
        padding-top: 12vh;
        background-image: url(../../../utils/images/headbg.jpg);
        background-repeat: no-repeat;
        background-size: auto 40vh;
        background-position: top center;
    }
    
    .title{
        color: white;
        font-size: 2.2vh;
        font-weight: bold;
        margin: 1.2vh 0vw;
    }
    
    .text{
        width: 70%;
        font-size: 2vh;
        text-align: center;
        color: #a8b3cd;
        line-height: normal;
    }

    .divIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 29vh;
        height: 12vw;
        width: 12vw;
        border-radius: 50%;
        background: #00c6fb;
    }
    
    .icon{
        color: white;
        font-size: 7vw;
    }
}