.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60vh;
    width: 100%;
    padding: 15vh 0vw 5vh 0vw;
    background: #28375e;
}

.fondoA {
    position: absolute;
    height: 37vh;
    width: 0vw;
    left: 0;
    top: 0;
    margin-top: 17vh;
    opacity: 0.45;
    background: linear-gradient(90deg, #6d70d1, #00c6fb);
    margin-left: 0%;
    animation: moverFondoA 0.8s forwards;
}

.fondoB {
    display: flex;
    position: absolute;
    right: 0;
    height: 37vh;
    width: 0vw;
    margin-top: 33vh;
    opacity: 0.45;
    background: linear-gradient(90deg, #0299f2, #28375e);
    margin-right: 0%;
    animation: moverFondoB 0.8s forwards;
}

.areaImagenes {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 37vw;
    z-index: 1;
}

.logo {
    position: absolute;
    width: 8vw;
    opacity: 0;
    animation: logoA 1s forwards;
    z-index: 2;
}

.imagen1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 13vh;
    width: 13vh;
    opacity: 0;
    margin: 0% 0% 0% 0%;
    background: #448ee7;
    border-radius: 50%;
    animation: imagenA 2.5s forwards;
}

.imagen2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 13vh;
    width: 13vh;
    opacity: 0;
    margin: 0% 0% 0% 0%;
    background: #448ee7;
    border-radius: 50%;
    animation: imagenB 2.5s forwards;
}

.imagen3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 13vh;
    width: 13vh;
    opacity: 0;
    margin: 0% 0% 0% 0%;
    background: #448ee7;
    border-radius: 50%;
    animation: imagenC 2.5s forwards;
}

.imagen4 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 13vh;
    width: 13vh;
    opacity: 0;
    margin: 0% 0% 0% 0%;
    background: #448ee7;
    border-radius: 50%;
    animation: imagenD 2.5s forwards;
}

.imagen{
    width: 4.5vw;
}

.areaTexto {
    display: flex;
    flex-direction: column;
    height: 40vh;
    width: 37vw;
    padding: 5vh 0vw 1vh 0vw;
    z-index: 1;
    opacity: 0;
    animation: logoA 1.5s forwards;
}

.sobre{
    color: white;
    font-weight: bold;
    font-size: 1vw;
}

.carrusel{
    display: flex;
    align-items: center;
    margin-top: 3vh;
    height: 25vh;
    width: 34.9vw;
    overflow: hidden;
}

.divText{
    display: flex;
    flex-direction: row;
    margin-left: 0%;
    animation: moverCarrusel 16s infinite;
}

.text1{
    display: flex;
    color: white;
    font-weight: bold;
    line-height: 5vh;
    height: 25vh;
    width: 35vw;
    font-size: 1.8vw;
    padding: 0vh 0vw;
    user-select: none;
}

.text2{
    display: flex;
    color: white;
    font-weight: bold;
    line-height: 5vh;
    height: 25vh;
    width: 35vw;
    font-size: 1.8vw;
    padding: 0vh 0vw;
    user-select: none;
}

@keyframes moverFondoA {
    100% {
        /* margin-left: 0; */
        width: 70vw;
    }
}

@keyframes moverFondoB {
    100% {/* 
        margin-right: 0; */
        width: 70vw;
    }
}

@keyframes logoA {
    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes imagenA {
    40% {
        margin: 0% 0% 0% 0%;
        opacity: 0;
    }
    70% {
        margin: 0% 20% 15% 0%;
        opacity: 1;
    }
    100% {
        margin: 0% 20% 15% 0%;
        opacity: 1;
    }
}

@keyframes imagenB {
    50% {
        margin: 0% 0% 0% 0%;
        opacity: 0;
    }
    80% {
        margin: 0% 0% 15% 20%;
        opacity: 1;
    }
    100% {
        margin: 0% 0% 15% 20%;
        opacity: 1;
    }
}

@keyframes imagenC {
    60% {
        margin: 0% 0% 0% 0%;
        opacity: 0;
    }
    90% {
        margin: 15% 20% 0% 0%;
        opacity: 1;
    }
    100% {
        margin: 15% 20% 0% 0%;
        opacity: 1;
    }
}

@keyframes imagenD {
    70% {
        margin: 0% 0% 0% 0%;
        opacity: 0;
    }
    100% {
        margin: 15% 0% 0% 20%;
        opacity: 1;
    }
}

@keyframes moverCarrusel {
    0%{
        margin-left: 0%;
    }
    40%{
        margin-left: 0%;
    }
    50%{
        margin-left: -100%;
    }
    90%{
        margin-left: -100%;
    }
    100%{
        margin-left: 0%;
    }
}




@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 68vh; 
        width: 100%;
        padding: 15vh 0vw 2vh 0vw;
        background: #28375e;
    }
    
    .fondoA {
        position: absolute;
        height: 37vh;
        width: 0vw;
        left: 0;
        top: 0;
        margin-top: 17vh;
        opacity: 0.45;
        background: linear-gradient(90deg, #6d70d1, #00c6fb);
        animation: moverFondoA 0.8s forwards;
    }
    
    .fondoB {
        display: flex;
        position: absolute;
        right: 0;
        height: 37vh;
        width: 0vw;
        margin-top: 33vh;
        opacity: 0.45;
        background: linear-gradient(90deg, #0299f2, #28375e);
        animation: moverFondoB 0.8s forwards;
    }
    
    .areaImagenes {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
        width: 90vw;
        z-index: 1;
    }
    
    .logo {
        position: absolute;
        width: 20vw;
        opacity: 0;
        animation: logoA 1s forwards;
        z-index: 2;
    }
    
    .imagen1 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 10vh;
        width: 10vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenA 2.5s forwards;
    }
    
    .imagen2 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 10vh;
        width: 10vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenB 2.5s forwards;
    }
    
    .imagen3 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 10vh;
        width: 10vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenC 2.5s forwards;
    }
    
    .imagen4 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 10vh;
        width: 10vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenD 2.5s forwards;
    }
    
    .imagen{
        width: 10vw;
    }
    
    .areaTexto {
        display: flex;
        flex-direction: column;
        height: 40vh;
        width: 90vw;
        padding: 3vh 1vw 0vh 1vw;
        z-index: 1;
        opacity: 0;
        animation: logoA 1.5s forwards;
    }
    
    .sobre{
        color: white;
        font-weight: bold;
        margin: 0vh;
        padding: 2vh 0 1vh 1vw;
    }
    
    .carrusel{
        display: flex;
        align-items: center;
        margin-top: 0vh;
        height: 20vh;
        width: 90vw;
        overflow: hidden;
    }
    
    .divText{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0%;
        animation: moverCarrusel 16s infinite;
    }
    
    .text1{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        color: white;
        font-weight: bold;
        height: 20vh;
        width: 88vw;
        font-size: 2.5vh;
        padding: 0vh 1vw;
        user-select: none;
        margin: 0;
    }
    
    .text2{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        color: white;
        font-weight: bold;
        height: 20vh;
        width: 88vw;
        font-size: 2.5vh;
        padding: 0vh 1vw;
        user-select: none;
        margin: 0;
    }

    @keyframes moverFondoA {
        100% {
            /* margin-left: 0; */
            width: 70vw;
        }
    }
    
    @keyframes moverFondoB {
        100% {/* 
            margin-right: 0; */
            width: 70vw;
        }
    }
    
    @keyframes logoA {
        70% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    
    @keyframes imagenA {
        40% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        70% {
            margin: 0% 50% 35% 0%;
            opacity: 1;
        }
        100% {
            margin: 0% 50% 35% 0%;
            opacity: 1;
        }
    }
    
    @keyframes imagenB {
        50% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        80% {
            margin: 0% 0% 35% 50%;
            opacity: 1;
        }
        100% {
            margin: 0% 0% 35% 50%;
            opacity: 1;
        }
    }
    
    @keyframes imagenC {
        60% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        90% {
            margin: 35% 50% 0% 0%;
            opacity: 1;
        }
        100% {
            margin: 35% 50% 0% 0%;
            opacity: 1;
        }
    }
    
    @keyframes imagenD {
        70% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        100% {
            margin: 35% 0% 0% 50%;
            opacity: 1;
        }
    }
    
    @keyframes moverCarrusel {
        0%{
            margin-left: 0%;
        }
        40%{
            margin-left: 0%;
        }
        50%{
            margin-left: -100%;
        }
        90%{
            margin-left: -100%;
        }
        100%{
            margin-left: 0%;
        }
    }
}

@media (max-width:650px) {
    .container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 70vh; 
        width: 100%;
        padding: 15vh 0vw 2vh 0vw;
        background: #28375e;
    }
    
    .fondoA {
        position: absolute;
        height: 37vh;
        width: 70vw;
        left: 0;
        top: 0;
        margin-top: 17vh;
        opacity: 0.45;
        background: linear-gradient(90deg, #6d70d1, #00c6fb);
        margin-left: -100%;
        animation: moverFondoA 0.8s forwards;
    }
    
    .fondoB {
        display: flex;
        position: absolute;
        right: 0;
        height: 37vh;
        width: 70vw;
        margin-top: 33vh;
        opacity: 0.45;
        background: linear-gradient(90deg, #0299f2, #28375e);
        margin-right: -100%;
        animation: moverFondoB 0.8s forwards;
    }
    
    .areaImagenes {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
        width: 90vw;
        z-index: 1;
    }
    
    .logo {
        position: absolute;
        width: 30vw;
        opacity: 0;
        animation: logoA 1s forwards;
        z-index: 2;
    }
    
    .imagen1 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 11vh;
        width: 11vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenA 2.5s forwards;
    }
    
    .imagen2 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 11vh;
        width: 11vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenB 2.5s forwards;
    }
    
    .imagen3 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 11vh;
        width: 11vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenC 2.5s forwards;
    }
    
    .imagen4 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 11vh;
        width: 11vh;
        opacity: 0;
        margin: 0% 0% 0% 0%;
        background: #448ee7;
        border-radius: 50%;
        animation: imagenD 2.5s forwards;
    }
    
    .imagen{
        width: 12vw;
    }
    
    .areaTexto {
        display: flex;
        flex-direction: column;
        height: 40vh;
        width: 90vw;
        padding: 3vh 1vw 0vh 1vw;
        z-index: 1;
        opacity: 0;
        animation: logoA 1.5s forwards;
    }
    
    .sobre{
        color: white;
        font-weight: bold;
        font-size: 1.8vh;
        margin: 0vh;
        padding: 2vh 0 1vh 1vw;
    }
    
    .carrusel{
        display: flex;
        align-items: normal;
        margin-top: 0vh;
        height: auto;
        width: 90vw;
        overflow: hidden;
    }
    
    .divText{
        display: flex;
        flex-direction: row;
        align-items: normal;
        margin-left: 0%;
        animation: moverCarrusel 16s infinite;
    }
    
    .text1{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        color: white;
        font-weight: bold;
        height: auto;
        width: 88vw;
        font-size: 2.5vh;
        padding: 0vh 1vw;
        user-select: none;
        margin: 0;
    }
    
    .text2{
        display: flex;
        align-items: normal;
        justify-content: center;
        text-align: left;
        color: white;
        font-weight: bold;
        height: auto;
        width: 88vw;
        font-size: 2.5vh;
        padding: 0vh 1vw;
        user-select: none;
        margin: 0;
    }
    
    @keyframes moverFondoA {
        100% {
            margin-left: 0;
        }
    }
    
    @keyframes moverFondoB {
        100% {
            margin-right: 0;
        }
    }
    
    @keyframes logoA {
        70% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    
    @keyframes imagenA {
        40% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        70% {
            margin: 0% 60% 45% 0%;
            opacity: 1;
        }
        100% {
            margin: 0% 60% 45% 0%;
            opacity: 1;
        }
    }
    
    @keyframes imagenB {
        50% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        80% {
            margin: 0% 0% 45% 60%;
            opacity: 1;
        }
        100% {
            margin: 0% 0% 45% 60%;
            opacity: 1;
        }
    }
    
    @keyframes imagenC {
        60% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        90% {
            margin: 45% 60% 0% 0%;
            opacity: 1;
        }
        100% {
            margin: 45% 60% 0% 0%;
            opacity: 1;
        }
    }
    
    @keyframes imagenD {
        70% {
            margin: 0% 0% 0% 0%;
            opacity: 0;
        }
        100% {
            margin: 45% 0% 0% 60%;
            opacity: 1;
        }
    }
    
    @keyframes moverCarrusel {
        0%{
            margin-left: 0%;
        }
        40%{
            margin-left: 0%;
        }
        50%{
            margin-left: -100%;
        }
        90%{
            margin-left: -100%;
        }
        100%{
            margin-left: 0%;
        }
    }
}