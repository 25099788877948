.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 85vh;
    width: 100%;
    background: white;
}

.divIzq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 35vw;
}

.divText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #689af1;
    padding: 6vh 3.5vw;
    border-radius: 1.5vh;
    transform: rotateX(60deg) scale(0.5) translateY(50px);
    opacity: 0;
    animation: desplazar 0.3s forwards;
}

.divNone {
    display: none;
}

.divTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2vh;
}

.title {
    color: white;
    font-size: 1.6vw;
    font-weight: bold;
    margin: 1vh 0vw;
    margin-left: 2vw;
}

.text {
    color: #e9f1ff;
    font-size: 1.1vw;
    line-height: 3.5vh;
    margin: 1vh 0vw;
}

.ul {
    padding: 0vh 2vw;
    margin: 0;
}

.li {
    color: white;
    font-size: 1.1vw;
    line-height: 3vh;
    margin: 0.5vh 0vw;
}

.divDer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 35vw;
}

.divLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 12vw;
    width: 12vw;
    margin-right: -20vw;
    border-radius: 50%;
    box-shadow: 0 3px 37px rgba(112, 145, 179, 0.22);
}

.logo {
    height: 11vh;
}

.divOption1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-right: 0vw;
    margin-top: -19vw;
}

.divOption2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-right: 14vw;
    margin-top: -13vw;
}

.divOption3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-right: 20vw;
    margin-top: 0vh;
}

.divOption4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-right: 14vw;
    margin-top: 13vw;
}

.divOption5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    margin-right: 0vw;
    margin-top: 19vw;
}

.divOption {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    margin-left: -12vw;
    width: 11vw;
}

.option {
    font-size: 0.8vw;
    font-weight: bold;
    white-space: nowrap;
}

.option:hover {
    cursor: pointer;
}

.optionSelected {
    font-size: 0.8vw;
    font-weight: bold;
    white-space: nowrap;
}

.divIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.7vw;
    width: 3.7vw;
    border-radius: 50%;
    background: #689af1;
}

.divIconHover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.7vw;
    width: 3.7vw;
    border-radius: 50%;
    background: #373553;
}

.divIconSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.7vw;
    width: 3.7vw;
    border-radius: 50%;
    background: #373553;
}

.divIconHover:hover {
    cursor: pointer;
}

.icon {
    width: 2.2vw;
    -webkit-user-drag: none;
}

@keyframes desplazar {
    100%{
        transform: rotateX(0deg) scale(1) translateY(0px);
        opacity: 1;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 85vh;
        width: 100%;
        background: white;
    }
    
    .divIzq {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
        width: 40vw;
    }
    
    .divText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #689af1;
        padding: 6vh 3.5vw;
        border-radius: 1.5vh;
        transform: rotateX(60deg) scale(0.5) translateY(50px);
        opacity: 0;
        animation: desplazar 0.3s forwards;
    }
    
    .divNone {
        display: none;
    }
    
    .divTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 2vh;
    }
    
    .title {
        color: white;
        font-size: 4vh;
        font-weight: bold;
        margin: 1vh 0vw;
        margin-left: 2vw;
    }
    
    .text {
        color: #e9f1ff;
        font-size: 2.5vh;
        line-height: 4.5vh;
        margin: 1vh 0vw;
    }
    
    .ul {
        padding: 0vh 2vw;
        margin: 0;
    }
    
    .li {
        color: white;
        font-size: 2.1vh;
        line-height: 3vh;
        margin: 0.5vh 0vw;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
        width: 35vw;
    }
    
    .divLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 12vw;
        width: 12vw;
        margin-right: -20vw;
        border-radius: 50%;
        box-shadow: 0 3px 37px rgba(112, 145, 179, 0.22);
    }
    
    .logo {
        height: 11vh;
    }
    
    .divOption1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 0vw;
        margin-top: -22vw;
    }
    
    .divOption2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 16vw;
        margin-top: -15vw;
    }
    
    .divOption3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 22vw;
        margin-top: 0vh;
    }
    
    .divOption4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 16vw;
        margin-top: 15vw;
    }
    
    .divOption5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 0vw;
        margin-top: 22vw;
    }
    
    .divOption {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        margin-left: -12vw;
        width: 11vw;
    }
    
    .option {
        font-size: 1vw;
        font-weight: bold;
        white-space: nowrap;
    }
    
    .optionSelected {
        font-size: 1vw;
        font-weight: bold;
        white-space: nowrap;
    }
    
    .divIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vw;
        width: 5vw;
        border-radius: 50%;
        background: #689af1;
    }
    
    .divIconHover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vw;
        width: 5vw;
        border-radius: 50%;
        background: #373553;
    }
    
    .divIconSelected {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vw;
        width: 5vw;
        border-radius: 50%;
        background: #373553;
    }
    
    .icon {
        width: 2.5vw;
        -webkit-user-drag: none;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        background: white;
    }
    
    .divIzq {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        width: 88vw;
        padding-bottom: 3vh;
    }
    
    .divText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #689af1;
        padding: 4vh 5vw;
        border-radius: 1.5vh;
        transform: rotateX(60deg) scale(0.5) translateY(50px);
        opacity: 0;
        animation: desplazar 0.3s forwards;
    }
    
    .divNone {
        display: none;
    }
    
    .divTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 2vh;
    }
    
    .title {
        color: white;
        font-size: 3.5vh;
        font-weight: bold;
        margin: 1vh 0vw;
        margin-left: 3vw;
    }
    
    .text {
        color: #e9f1ff;
        font-size: 2vh;
        line-height: 3.5vh;
        margin: 1vh 0vw;
    }
    
    .ul {
        padding: 0vh 2vw;
        margin: 0;
    }
    
    .li {
        color: white;
        font-size: 2.1vh;
        line-height: 3vh;
        margin: 0.5vh 0vw;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 35vw;
    }
    
    .divLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 30vw;
        width: 30vw;
        margin-right: 0vw;
        margin-bottom: 5vh;
        margin-top: 35vw;
        border-radius: 50%;
        box-shadow: 0 3px 37px rgba(112, 145, 179, 0.22);
    }
    
    .logo {
        height: auto;
        width: 25vw;
    }
    
    .divOption1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 60vw;
        margin-top: 0vw;
    }
    
    .divOption2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 42vw;
        margin-top: -42vw;
    }
    
    .divOption3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 0vw;
        margin-top: -60vw;
    }
    
    .divOption4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: -42vw;
        margin-top: -42vw;
    }
    
    .divOption5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: -60vw;
        margin-top: 0vw;
    }
    
    .divOption {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        margin-left: -12vw;
        width: 11vw;
    }
    
    .option {
        display: none;
        font-size: 0.8vw;
        font-weight: bold;
        white-space: nowrap;
    }
    
    .optionSelected {
        display: none;
        font-size: 0.8vw;
        font-weight: bold;
        white-space: nowrap;
    }
    
    .divIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vw;
        width: 13vw;
        border-radius: 50%;
        background: #689af1;
    }
    
    .divIconHover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vw;
        width: 13vw;
        border-radius: 50%;
        background: #373553;
    }
    
    .divIconSelected {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vw;
        width: 13vw;
        border-radius: 50%;
        background: #373553;
    }
    
    .icon {
        width: 7vw;
        -webkit-user-drag: none;
    }
}

@media (max-width:650px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        background: white;
    }
    
    .divIzq {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        width: 88vw;
        padding-bottom: 3vh;
    }
    
    .divText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #689af1;
        padding: 4vh 5vw;
        border-radius: 1.5vh;
        transform: rotateX(60deg) scale(0.5) translateY(50px);
        opacity: 0;
        animation: desplazar 0.3s forwards;
    }
    
    .divNone {
        display: none;
    }
    
    .divTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 2vh;
    }
    
    .title {
        color: white;
        font-size: 3.5vh;
        font-weight: bold;
        margin: 1vh 0vw;
        margin-left: 3vw;
    }
    
    .text {
        color: #e9f1ff;
        font-size: 2.1vh;
        line-height: 3.5vh;
        margin: 1vh 0vw;
    }
    
    .ul {
        padding: 0vh 2vw;
        margin: 0;
    }
    
    .li {
        color: white;
        font-size: 2.1vh;
        line-height: 3vh;
        margin: 0.5vh 0vw;
    }
    
    .divDer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 35vw;
    }
    
    .divLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 40vw;
        width: 40vw;
        margin-right: 0vw;
        margin-bottom: 5vh;
        margin-top: 35vw;
        border-radius: 50%;
        box-shadow: 0 3px 37px rgba(112, 145, 179, 0.22);
    }
    
    .logo {
        height: auto;
        width: 35vw;
    }
    
    .divOption1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 70vw;
        margin-top: 0vw;
    }
    
    .divOption2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 50vw;
        margin-top: -50vw;
    }
    
    .divOption3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: 0vw;
        margin-top: -70vw;
    }
    
    .divOption4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: -50vw;
        margin-top: -50vw;
    }
    
    .divOption5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        margin-right: -70vw;
        margin-top: 0vw;
    }
    
    .divOption {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        margin-left: -12vw;
        width: 11vw;
    }
    
    .option {
        display: none;
        font-size: 0.8vw;
        font-weight: bold;
        white-space: nowrap;
    }
    
    .optionSelected {
        display: none;
        font-size: 0.8vw;
        font-weight: bold;
        white-space: nowrap;
    }
    
    .divIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20vw;
        width: 20vw;
        border-radius: 50%;
        background: #689af1;
    }
    
    .divIconHover {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20vw;
        width: 20vw;
        border-radius: 50%;
        background: #373553;
    }
    
    .divIconSelected {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20vw;
        width: 20vw;
        border-radius: 50%;
        background: #373553;
    }
    
    .icon {
        width: 10vw;
        -webkit-user-drag: none;
    }
}