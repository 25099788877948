.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 7vh 0vw 4vh 0vw;
}

.detalle {
    font-size: 1.5vw;
    margin: 1vh 0vw;
    color: #6a6978;
}

.separador {
    width: 4%;
    border: solid 0.1vh;
}

.tabla {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 71vw;
    margin: 8vh 0vw;
    border-radius: 1vh;
    border: solid 0.1vh #d3d3dd;
}

.fila {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 9vh;
    width: 71vw;
    border-bottom: solid 0.1vh #d3d3dd;
    background: #fcfcfd;
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
}

.nombre {
    display: flex;
    align-items: center;
    height: 9vh;
    width: 54vw;
    padding: 0vh 3vw;
    color: #0091fb;
    font-size: 1vw;
    font-weight: bold;
    letter-spacing: 0.1vh;
    border-right: solid 0.1vh #d3d3dd;
}

.horas {
    display: flex;
    align-items: center;
    height: 9vh;
    width: 5vw;
    padding: 0vh 3vw;
    font-size: 1vw;
    font-weight: bold;
    letter-spacing: 0.1vh;
    color: #0091fb;
}

.filaA {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 9vh;
    width: 71vw;
    background: #fcfcfd;
}

.filaB {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 9vh;
    width: 71vw;
    background: #f6f6f9;
}

.filaAEnd {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 9vh;
    width: 71vw;
    background: #fcfcfd;
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
}

.filaBEnd {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 9vh;
    width: 71vw;
    background: #f6f6f9;
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
}

.text1 {
    display: flex;
    align-items: center;
    height: 9vh;
    width: 54vw;
    padding: 0vh 3vw;
    color: #4f5459;
    font-size: 0.8vw;
    font-weight: bold;
    letter-spacing: 0.1vh;
    white-space: nowrap;
    border-right: solid 0.1vh #d3d3dd;
}

.text2 {
    display: flex;
    align-items: center;
    height: 9vh;
    width: 5vw;
    padding: 0vh 3vw;
    font-size: 0.8vw;
    letter-spacing: 0.1vh;
    color: #4f5459;
    white-space: nowrap;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4vh 0vw 2vh 0vw;
    }
    
    .detalle {
        font-size: 3vh;
        margin: 1vh 0vw;
        color: #6a6978;
    }
    
    .separador {
        width: 12%;
        margin-top: 1vh;
        border: solid 0.1vh;
    }
    
    .tabla {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85vw;
        margin: 3vh 0vw;
        border-radius: 1vh;
        border: solid 0.1vh #d3d3dd;
    }
    
    .fila {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 7vh;
        width: 85vw;
        border-bottom: solid 0.1vh #d3d3dd;
        background: #fcfcfd;
        border-top-left-radius: 1vh;
        border-top-right-radius: 1vh;
    }
    
    .nombre {
        display: flex;
        align-items: center;
        height: 7vh;
        width: 54vw;
        padding: 0vh 3vw;
        color: #0091fb;
        font-size: 1.5vh;
        font-weight: bold;
        letter-spacing: 0.1vh;
        border-right: solid 0.1vh #d3d3dd;
    }
    
    .horas {
        display: flex;
        align-items: center;
        height: 7vh;
        width: 5vw;
        padding: 0vh 3vw;
        font-size: 1.5vh;
        font-weight: bold;
        letter-spacing: 0.1vh;
        color: #0091fb;
    }
    
    .filaA {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 7vh;
        width: 85vw;
        background: #fcfcfd;
    }
    
    .filaB {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 7vh;
        width: 85vw;
        background: #f6f6f9;
    }
    
    .filaAEnd {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 7vh;
        width: 85vw;
        background: #fcfcfd;
        border-bottom-left-radius: 1vh;
        border-bottom-right-radius: 1vh;
    }
    
    .filaBEnd {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 7vh;
        width: 85vw;
        background: #f6f6f9;
        border-bottom-left-radius: 1vh;
        border-bottom-right-radius: 1vh;
    }
    
    .text1 {
        display: flex;
        align-items: center;
        height: 7vh;
        width: 54vw;
        padding: 0vh 3vw;
        margin: 0;
        color: #4f5459;
        font-size: 1.2vh;
        font-weight: bold;
        letter-spacing: 0.1vh;
        white-space: normal;
        border-right: solid 0.1vh #d3d3dd;
    }
    
    .text2 {
        display: flex;
        align-items: center;
        height: 7vh;
        width: 5vw;
        padding: 0vh 3vw;
        font-size: 1.2vh;
        letter-spacing: 0.1vh;
        color: #4f5459;
        white-space: nowrap;
    }
}

@media (max-width:650px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4vh 0vw 2vh 0vw;
    }
    
    .detalle {
        font-size: 3vh;
        margin: 1vh 0vw;
        color: #6a6978;
    }
    
    .separador {
        width: 12%;
        margin-top: 1vh;
        border: solid 0.1vh;
    }
    
    .tabla {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85vw;
        margin: 3vh 0vw;
        border-radius: 1vh;
        border: solid 0.1vh #d3d3dd;
    }
    
    .fila {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9vh;
        width: 85vw;
        border-bottom: solid 0.1vh #d3d3dd;
        background: #fcfcfd;
        border-top-left-radius: 1vh;
        border-top-right-radius: 1vh;
    }
    
    .nombre {
        display: flex;
        align-items: center;
        height: 9vh;
        width: 54vw;
        padding: 0vh 3vw;
        color: #0091fb;
        font-size: 1.9vh;
        font-weight: bold;
        letter-spacing: 0.1vh;
        border-right: solid 0.1vh #d3d3dd;
    }
    
    .horas {
        display: flex;
        align-items: center;
        height: 9vh;
        width: 5vw;
        padding: 0vh 3vw;
        font-size: 1.9vh;
        font-weight: bold;
        letter-spacing: 0.1vh;
        color: #0091fb;
    }
    
    .filaA {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9vh;
        width: 85vw;
        background: #fcfcfd;
    }
    
    .filaB {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9vh;
        width: 85vw;
        background: #f6f6f9;
    }
    
    .filaAEnd {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9vh;
        width: 85vw;
        background: #fcfcfd;
        border-bottom-left-radius: 1vh;
        border-bottom-right-radius: 1vh;
    }
    
    .filaBEnd {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9vh;
        width: 85vw;
        background: #f6f6f9;
        border-bottom-left-radius: 1vh;
        border-bottom-right-radius: 1vh;
    }
    
    .text1 {
        display: flex;
        align-items: center;
        height: 9vh;
        width: 54vw;
        padding: 0vh 3vw;
        margin: 0;
        color: #4f5459;
        font-size: 1.6vh;
        font-weight: bold;
        letter-spacing: 0.1vh;
        white-space: normal;
        border-right: solid 0.1vh #d3d3dd;
    }
    
    .text2 {
        display: flex;
        align-items: center;
        height: 9vh;
        width: 5vw;
        padding: 0vh 3vw;
        font-size: 1.6vh;
        letter-spacing: 0.1vh;
        color: #4f5459;
        white-space: nowrap;
    }
}