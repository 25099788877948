.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8vh 0vw;
}

.titulo {
    font-size: 1.7vw;
    line-height: 5vh;
    text-align: center;
    font-weight: bold;
    width: 40%;
}

.divContainer {
    display: flex;
    flex-direction: row;
}

.divIzq {
    display: flex;
    flex-direction: column;
    width: 35vw;
    padding: 0vh 1vw;
}

.divDer {
    display: flex;
    flex-direction: column;
    width: 35vw;
    padding: 0vh 1vw;
}

.text {
    font-size: 0.9vw;
    line-height: 3.2vh;
}

.textA {
    font-size: 1vw;
    font-weight: bolder;
    line-height: 3.2vh;
}

.title {
    font-size: 1vw;
    font-weight: bold;
    letter-spacing: 0.1vw;
}

.separador {
    border: solid 0.1vh;
    width: 4vw;
}

.ul {
    padding-left: 25px;
}

.li {
    font-size: 0.8vw;
    margin: 1.4vh 0vw 1.8vh 0vw;
    line-height: 2.5vh;
}

.li::marker {
    color: #00c6fb;
    content: '●  ';
    font-size: 1.3vw;
}

@media only screen and (min-width: 821px) and (max-width: 1150px) {}

@media only screen and (min-width: 651px) and (max-width: 820px) {
    
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4vh 0vw;
    }

    .titulo {
        font-size: 2.5vh;
        line-height: 4vh;
        width: 90%;
        text-align: center;
        font-weight: bold;
    }

    .divContainer {
        display: flex;
        flex-direction: column;
    }

    .divIzq {
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }

    .divDer {
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }

    .text {
        font-size: 1.8vh;
        line-height: 3.2vh;
    }

    .title {
        font-size: 2.2vh;
        font-weight: bold;
        letter-spacing: 0.1vw;
    }

    .separador {
        border: solid 0.1vh;
        width: 12vw;
    }

    .ul {
        padding-left: 25px;
    }

    .li {
        font-size: 1.5vh;
        margin: 1vh 0vw;
    }

    .li::marker {
        color: #00c6fb;
        content: '●  ';
        font-size: 2.5vh;
    }
}

@media (max-width:650px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4vh 0vw;
    }

    .titulo {
        font-size: 2.5vh;
        line-height: 4vh;
        width: 90%;
        text-align: center;
        font-weight: bold;
    }

    .divContainer {
        display: flex;
        flex-direction: column;
    }

    .divIzq {
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }

    .divDer {
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding: 0vh 1vw;
    }

    .text {
        font-size: 1.8vh;
        line-height: 3.2vh;
    }

    .title {
        font-size: 2.2vh;
        font-weight: bold;
        letter-spacing: 0.1vw;
    }

    .separador {
        border: solid 0.1vh;
        width: 12vw;
    }

    .ul {
        padding-left: 25px;
    }

    .li {
        font-size: 1.5vh;
        margin: 1vh 0vw;
    }

    .li::marker {
        color: #00c6fb;
        content: '●  ';
        font-size: 2.5vh;
    }
}