.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 65vh;
    width: 80vw;
    padding: 8vh 0vw;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 62vh;
    width: 50%;
    padding: 1vh 5vw;
}

.text{
    font-size: 1.5vw;
    color: #3f3d59;
    margin: 2vh 0vw;
}

.separador{
    border: #3f3d59 solid 0.1vh;
    width: 10%;
}

.input{
    display: flex;
    height: 5vh;
    font-size: 0.75vw;
    padding: 0.5vh 1vw;
    background: #dde6f9;
    border: solid 0.1vh #dde6f9;
    border-radius: 0.6vh;
}

.mensaje{
    display: flex;
    height: 15vh;
    font-size: 0.9vw;
    padding: 1vh 1vw;
    background: #dde6f9;
    border: solid 0.1vh #dde6f9;
    border-radius: 0.6vh;
    resize: none;
}

.enviar{
    display: flex;
    align-items: center;
    height: 5vh;
    width: 96%;
    font-size: 0.9vw;
    font-weight: bold;
    color: white;
    padding: 0.5vh 2%;
    background: #4CAF50;
    border-radius: 0.6vh;
    border: none;
}

.enviar:hover{
    cursor: pointer;
    background: #45a049;
}

.map {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    width: 40vw;
}

.mapa{
    display: flex;
    height: 100%;
    width: 80%;
    border: none;
}

.enviandoFalse{
    display: flex;
    margin: 0;
    opacity: 0;
    user-select: none;
}

.enviandoTrue{
    margin: 0;
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 65vh;
        width: 80vw;
        padding: 8vh 0vw;
    }
    
    .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 60vh;
        width: 50%;
        padding: 1vh 5vw;
    
    }
    
    .text{
        font-size: 3vh;
        color: #3f3d59;
        margin: 2vh 0vw;
    }
    
    .separador{
        border: #3f3d59 solid 0.1vh;
        width: 10%;
    }
    
    .input{
        display: flex;
        height: 5vh;
        font-size: 2vh;
        padding: 0.5vh 1vw;
        background: #dde6f9;
        border: solid 0.1vh #dde6f9;
        border-radius: 0.6vh;
    }
    
    .mensaje{
        display: flex;
        height: 15vh;
        font-size: 2vh;
        padding: 1vh 1vw;
        background: #dde6f9;
        border: solid 0.1vh #dde6f9;
        border-radius: 0.6vh;
        resize: none;
    }
    
    .enviar{
        display: flex;
        align-items: center;
        height: 5vh;
        font-size: 2vh;
        font-weight: bold;
        color: white;
        padding: 0.5vh 1.3vw;
        background: #4CAF50;
        border-radius: 0.6vh;
    }
    
    .enviar:hover{
        cursor: pointer;
        background: #45a049;
    }
    
    .map {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60vh;
        width: 40vw;
    }
    
    .mapa{
        display: flex;
        height: 100%;
        width: 80%;
        border: none;
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .container {
        flex-direction: column;
        height: auto;
        width: 90vw;
        padding: 2vh 0vw;
    }
    
    .form {
        height: 50vh;
        width: 70vw;
        padding: 3vh 5vw;
    
    }
    
    .text{
        font-size: 2.5vh;
        color: #3f3d59;
        margin: 2vh 0vw;
    }
    
    .separador{
        border: #3f3d59 solid 0.1vh;
        width: 10%;
    }
    
    .input{
        font-size: 1.2vh;
        padding: 0vh 3vw;
    }
    
    .mensaje{
        display: flex;
        height: 10vh;
        font-size: 1.2vh;
        padding: 1vh 3vw;
        background: #dde6f9;
        border: solid 0.1vh #dde6f9;
        border-radius: 0.6vh;
        resize: none;
    }
    
    .enviar{
        justify-content: center;
        font-size: 1.2vh;
        padding: 0vh 0vw;
        width: 60vw;
        margin-left: 5vw;
    }
    
    .map {
        height: 70vw;
        width: 70vw;
        padding: 3vh 5vw;
    }
    
    .mapa{
        display: flex;
        height: 100%;
        width: 100%;
        border: none;
    }
}

@media (max-width:650px) {
    .container {
        flex-direction: column;
        height: auto;
        width: 90vw;
        padding: 2vh 0vw;
    }
    
    .form {
        height: 60vh;
        width: 80vw;
        padding: 3vh 5vw;
    
    }
    
    .text{
        font-size: 3vh;
        color: #3f3d59;
        margin: 2vh 0vw;
    }
    
    .separador{
        border: #3f3d59 solid 0.1vh;
        width: 10%;
    }
    
    .input{
        font-size: 1.6vh;
        padding: 0.5vh 3vw;
    }
    
    .mensaje{
        display: flex;
        height: 15vh;
        font-size: 2vh;
        padding: 1vh 3vw;
        background: #dde6f9;
        border: solid 0.1vh #dde6f9;
        border-radius: 0.6vh;
        resize: none;
    }
    
    .enviar{
        justify-content: center;
        padding: 0.5vh 1.3vw;
    }
    
    .map {
        height: 80vw;
        width: 80vw;
        padding: 3vh 5vw;
    }
    
    .mapa{
        display: flex;
        height: 100%;
        width: 100%;
        border: none;
    }
}