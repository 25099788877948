.elearning{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 5vh;
}

.text1{
    font-size: 1.3vw;
    font-weight: bold;
    text-align:center;
}

.text2{
    width: 52vw;
    font-size: 1.4vw;
    color: gray;
    text-align:center;
}

.cardsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10vh;
    height: 40vh;
    width: 65vw;
}

.cardContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 37.2vh;
    width: 12vw;
    border-radius: 2vh;
    border-top-right-radius: 6vw;
    border-top-left-radius: 6vw;
    margin-top: -10vh;
}

.cardContainer:hover{
    cursor: pointer;
}

.cardFalse{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 20vh;
    width: 12vw;
    border: solid rgb(178, 178, 178) 0.1vh;
    border-radius: 2vh;
    padding-top: 7vh;
    background: #ebebeb;
}

.cardTrue{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 20vh;
    width: 12vw;
    border: solid #4072f8 0.1vh;
    border-radius: 2vh;
    padding-top: 7vh;
    background: #4072f8;
    cursor: pointer;
}

.cardImagenFalse{
    display: flex;
    position: absolute;
    width: 8vw;
    margin-top: -17vh;
    border-radius: 50%;
}

.cardImagenTrue{
    display: flex;
    position: absolute;
    width: 8vw;
    margin-top: -17vh;
    border-radius: 50%;
    animation: moverImagen 0.5s forwards;
}

.textCard1False{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
    margin: 0vh;
    height: 11vh;
    width: 11vw;
    color: black;
}

.textCard1True{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
    margin: 0vh;
    height: 11vh;
    width: 11vw;
    color: white;
}

.textCard2False{
    font-size: 1vw;
    color: grey;
    margin: 0vh 0vw 1.6vh 0vw;
}

.textCard2True{
    font-size: 1vw;
    color: rgb(199, 199, 199);
    margin: 0vh 0vw 1.6vh 0vw;
}

.verCursoFalse{
    font-size: 1vw;
    font-weight: bold;
    color: #00c6fb;
    margin-top: 0vh;
}

.verCursoTrue{
    font-size: 1vw;
    font-weight: bold;
    color: white;
    margin-top: 0vh;
}

.link{
    text-decoration: none;
}

.verProductos {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    width: 13vw;
    border-radius: 1.2vh;
    font-size: 0.9vw;
    color: white;
    background: #5987f7;
    font-weight: bold;
    white-space: nowrap;
}

.verProductos:hover {
    animation: backgroundProductos 0.3s forwards;
    cursor: pointer;
}

@keyframes moverImagen{
    100%{
        margin-top: -16.2vh;
    }
}

@keyframes backgroundProductos {
    100% {
        background: grey;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1150px){
    
    .text1{
        font-size: 4.5vh;
    }
    
    .text2{
        font-size: 3.7vh;
    }
    
    .cardsContainer{
        width: 70vw;
    }
    
    .cardContainer{
        height: 40.2vh;
        width: 13vw;
    }
    
    .cardFalse{
        height: 20vh;
        width: 13vw;
    }
    
    .cardTrue{
        height: 20vh;
        width: 13vw;
    }
    
    .cardImagenFalse{
        width: 9vw;
        margin-top: -20vh;
    }
    
    .cardImagenTrue{
        width: 9vw;
        margin-top: -20vh;
    }
    
    .textCard1False{
        font-size: 2.5vh;
    }
    
    .textCard1True{
        font-size: 2.5vh;
    }
    
    .textCard2False{
        font-size: 2.5vh;
    }
    
    .textCard2True{
        font-size: 2.5vh;
    }
    
    .verCursoFalse{
        font-size: 2.5vh;
    }
    
    .verCursoTrue{
        font-size: 2.5vh;
    }

    .verProductos {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        width: 17vw;
        border-radius: 1.2vh;
        font-size: 2.5vh;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }

    @keyframes moverImagen{
        100%{
            margin-top: -18.5vh;
        }
    }
}

@media only screen and (min-width: 651px) and (max-width: 820px){
    .elearning{
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .text1{
        font-size: 2vh;
    }
    
    .text2{
        width: 75vw;
        font-size: 2vh;
    }
    
    .cardsContainer{
        flex-direction: column;
        justify-content: center;
        margin-top: 2vh;
        height: auto;
        width: 80vw;
    }
    
    .cardContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 80vw;
        border-radius: 2vh;
        border-top-right-radius: 6vw;
        border-top-left-radius: 6vw;
        margin-top: 0vh;
        margin-bottom: 2vh;
    }
    
    .cardFalse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 18vh;
        width: 30vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 35vw;
        padding-right: 5vw;
    }
    
    .cardTrue{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 18vh;
        width: 30vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 35vw;
        padding-right: 5vw;
        cursor: pointer;
    }
    
    .cardImagenFalse{
        width: 25vw;
        margin-top: 0vh;
        margin-left: -65%;
        border-radius: 50%;
        border: 0.1vw solid;
    }
    
    .cardImagenTrue{
        width: 30vw;
        margin-top: 0vh;
        margin-left: -65%;
        border-radius: 50%;
        border: 0.1vw solid;
        animation: none;
    }
    
    .textCard1False{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2vh;
        font-weight: bold;
        text-align: center;
        margin: 0vh;
        height: 11vh;
        width: auto;
        color: black;
    }
    
    .textCard1True{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2vh;
        font-weight: bold;
        text-align: center;
        margin: 0vh;
        height: 11vh;
        width: 11vw;
        color: white;
    }
    
    .textCard2False{
        font-size: 2vh;
        color: grey;
        margin: 0vh 0vw 1.6vh 0vw;
    }
    
    .textCard2True{
        font-size: 2vh;
        color: rgb(199, 199, 199);
        margin: 0vh 0vw 1.6vh 0vw;
    }
    
    .verCursoFalse{
        font-size: 2vh;
        font-weight: bold;
        color: #00c6fb;
        margin-top: 0vh;
    }
    
    .verCursoTrue{
        font-size: 2vh;
        font-weight: bold;
        color: white;
        margin-top: 0vh;
    }
    
    .link{
        text-decoration: none;
    }

    .verProductos {
        height: 5vh;
        width: 40vw;
        border-radius: 1vh;
        font-size: 1.5vh;
        text-align: center;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }
}

@media (max-width:650px) {
    .elearning{
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    
    .text2{
        width: 80vw;
        font-size: 2.5vh;
    }
    
    .cardsContainer{
        flex-direction: column;
        justify-content: center;
        margin-top: 2vh;
        height: auto;
        width: 80vw;
    }
    
    .cardContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 80vw;
        border-radius: 2vh;
        border-top-right-radius: 6vw;
        border-top-left-radius: 6vw;
        margin-top: 0vh;
        margin-bottom: 2vh;
    }
    
    .cardFalse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20vh;
        width: 35vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 40vw;
        padding-right: 5vw;
    }
    
    .cardTrue{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20vh;
        width: 35vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 40vw;
        padding-right: 5vw;
        cursor: pointer;
    }
    
    .cardImagenFalse{
        width: 30vw;
        margin-top: 0vh;
        margin-left: -70%;
        border-radius: 50%;
        border: 0.1vw solid;
    }
    
    .cardImagenTrue{
        width: 30vw;
        margin-top: 0vh;
        margin-left: -70%;
        border-radius: 50%;
        border: 0.1vw solid;
        animation: none;
    }
    
    .textCard1False{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2vh;
        font-weight: bold;
        text-align: center;
        margin: 0vh;
        height: 11vh;
        width: auto;
        color: black;
    }
    
    .textCard1True{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2vh;
        font-weight: bold;
        text-align: center;
        margin: 0vh;
        height: 11vh;
        width: 11vw;
        color: white;
    }
    
    .textCard2False{
        font-size: 2vh;
        color: grey;
        margin: 0vh 0vw 1.6vh 0vw;
    }
    
    .textCard2True{
        font-size: 2vh;
        color: rgb(199, 199, 199);
        margin: 0vh 0vw 1.6vh 0vw;
    }
    
    .verCursoFalse{
        font-size: 2vh;
        font-weight: bold;
        color: #00c6fb;
        margin-top: 0vh;
    }
    
    .verCursoTrue{
        font-size: 2vh;
        font-weight: bold;
        color: white;
        margin-top: 0vh;
    }
    
    .link{
        text-decoration: none;
    }

    .verProductos {
        height: 5vh;
        width: 50vw;
        border-radius: 1vh;
        font-size: 1.8vh;
        text-align: center;
        color: white;
        background: #5987f7;
        font-weight: bold;
    }
}