.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 5vh 0vw 3vh 0vw;
}

.titulo {
    font-size: 1.3vw;
    text-align: center;
    font-weight: bold;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cardContainerA {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cardA {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 22vh;
    width: 31vw;
    padding: 0vh 1.5vw;
    margin: 2vh 1vw;
}

.cardB {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 29vh;
    width: 14vw;
    padding: 4vh 1vw;
    margin: 2vh 1vw;
}

.divText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2vw;
}

.imagen {
    height: 13vh;
}

.textA {
    color: #5987f7;
    font-size: 1.1vw;
    font-weight: bold;
    margin-bottom: 0vh;
}

.textB {
    color: #878d90;
    font-size: 1vw;
}

.text1 {
    text-align: center;
    color: #5987f7;
    font-size: 1.1vw;
    font-weight: bold;
    margin-bottom: 0vh;
}

.text2 {
    text-align: center;
    color: #878d90;
    font-size: 1vw;
}

@media only screen and (min-width: 821px) and (max-width: 1150px) {}

@media only screen and (min-width: 651px) and (max-width: 820px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        padding: 5vh 0vw 3vh 0vw;
    }
    
    .titulo {
        font-size: 2.5vh;
        text-align: center;
        font-weight: bold;
    }
    
    .cardContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90vw;
    }
    
    .cardContainerA {
        display: grid;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: auto auto;
        width: 90vw;
    }
    
    .cardA {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 40vw;
        padding: 0vh 1.5vw;
        margin: 2vh 1vw;
    }
    
    .cardB {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        width: 40vw;
        padding: 0vh 1.5vw;
        margin: 2vh 1vw;
    }
    
    .divText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2vw;
    }
    
    .imagen {
        height: 13vh;
    }
    
    .textA {
        color: #5987f7;
        font-size: 2.2vh;
        font-weight: bold;
        margin-bottom: 0vh;
        text-align: center;
    }
    
    .textB {
        color: #878d90;
        font-size: 1.9vh;
        text-align: center;
    }
    
    .text1 {
        text-align: center;
        color: #5987f7;
        font-size: 2.2vh;
        font-weight: bold;
        margin-bottom: 0vh;
    }
    
    .text2 {
        text-align: center;
        color: #878d90;
        font-size: 1.9vh;
    }
}

@media (max-width:650px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        padding: 0vh 0vw 3vh 0vw;
    }

    .titulo {
        font-size: 2.5vh;
        text-align: center;
        font-weight: bold;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cardContainerA {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cardA {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 80vw;
        padding: 0vh 1.5vw;
        margin: 1vh 1vw;
    }

    .cardB {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        width: 80vw;
        padding: 4vh 1vw;
        margin: 1vh 1vw;
    }

    .divText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2vw;
    }

    .imagen {
        height: 13vh;
    }

    .textA {
        color: #5987f7;
        font-size: 2.2vh;
        font-weight: bold;
        margin-bottom: 0vh;
        text-align: center;
    }

    .textB {
        color: #878d90;
        font-size: 1.9vh;
        text-align: center;
    }

    .text1 {
        text-align: center;
        color: #5987f7;
        font-size: 2.2vh;
        font-weight: bold;
        margin-bottom: 0vh;
    }

    .text2 {
        text-align: center;
        color: #878d90;
        font-size: 1.9vh;
    }
}